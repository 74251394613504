import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button, Typography, CardActions, CircularProgress } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CustomSpinner from '../CustomSpinner'; // import the new spinner component
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import useStyles from './styles';

function PostsCarousel() {
    const classes = useStyles();
    const posts = useSelector((state) => state.posts.posts);
    const sortedPosts = posts && posts.length ? posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : null;
    const latestPosts = sortedPosts ? sortedPosts.slice(0, 5) : null;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const loadingStyles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: isMobile ? '20vh' : '30vh', // 20vh on mobile devices, 60vh otherwise
    };

    if (!sortedPosts) {
        return (
            <div style={loadingStyles}>
                <CustomSpinner  size="7em" style={{ color: '#1a866b' }} />
            </div>
        );
    }

    return (
        <div >
            <Carousel
                className={classes.carouselContainer}
                autoPlay={false}
                navButtonsAlwaysVisible={true}
                navButtonsProps={{
                    style: {
                        backgroundColor: 'white',
                        color: 'black'
                    }
                }}
                navButtonsWrapperProps={{
                    style: {
                        bottom: '20px',
                        top: 'unset',
                    }
                }}
                indicatorContainerProps={{
                    className: classes.indicatorContainer,
                }}
            >
                {latestPosts.map((post, i) => <PostItem key={i} post={post} />)}
            </Carousel>
        </div>
    );
}

function PostItem(props) {
    const classes = useStyles();
    const history = useHistory();

    const handleReadMore = () => {
        history.push(`/posts/${props.post._id}`);
    };

    return (
        <Paper className={`${classes.postItemContainer} ${classes.darkGradient}`}>
<img 
  src={props.post.selectedFile} 
  alt={props.post.title} 
  className={classes.postImage}
/>            <div className={classes.cardText}>
                <Typography className={classes.carouseltitle} variant="h4">{props.post.title}</Typography>
                <Typography className={classes.carouseltitletext}>{props.post.titletext}</Typography>
                <CardActions className={classes.cardActions}>
                    <Button
                        size="small"
                        color="white"
                        style={{ marginTop: '10px', color: 'black',
                        backgroundColor: '#ecff38' }}
                        onClick={handleReadMore}
                    >
                        weiter lesen <ArrowForwardIcon style={{ color: 'black' }} />
                    </Button>
                </CardActions>
            </div>
        </Paper>
    );
}

export default PostsCarousel;
