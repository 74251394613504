import React, { useEffect, useState } from 'react';
import { Container, Grid, Grow } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Local imports
import { getPostsBySearch } from '../../actions/posts';
import useStyles from './styles';
import Posts from '../Posts/Posts';

// Custom hook to get query params
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const SearchResults = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const query = useQuery();
    const location = useLocation();

    // Extract search query and tags from URL
    const searchQuery = query.get('searchQuery');
    const tags = query.get('tags') ? query.get('tags').split(',') : [];

    // Effects
    useEffect(() => {
        // Dispatch action to get posts based on search query and tags
        dispatch(getPostsBySearch({ search: searchQuery, tags: tags.join(',') }));
    }, [dispatch, searchQuery, tags, location]);

    // Render
    return (
        <Grow in>
            <Container>
                <Grid className={classes.mainContainer} container justifyContent="space-between" alignItems="stretch" spacing={3}>
                    {/* Displaying only posts that match the search criteria */}
                    <Grid item xs={12}><Posts /></Grid>
                </Grid>
            </Container>
        </Grow>
    );
};

export default SearchResults;
