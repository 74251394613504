import { makeStyles } from '@material-ui/core/styles';
import useStyles from './styles';

export default makeStyles((theme) => ({
  appBar: {
    borderRadius: 15,
    margin: '30px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    boxShadow: '0 0 0',
    backgroundColor: '#f8f7f4',
   
  },

  [theme.breakpoints.down('sm')]:{
    mainContainer:{
      flexDirection: 'column-reverse',
    }
  },
  [theme.breakpoints.down('xs')]:{
    mainContainer:{
      flexDirection: 'column-reverse',
    }
  },
  
  homeHeader: {
    flex:'1',
    justifyContent: 'center',
 
    paddingRight: '45px',
    display: 'flex',
   flexDirection:'column',
   
   
  },
  [theme.breakpoints.down('sm')]:{
    homeHeader:{
      height: '100vh',
    }
  },
  [theme.breakpoints.down('xs')]:{
    homeHeader:{
      height: '100vh',
    }
  },
  
  headtitle: {
   
    fontFamily: 'none',
  
    fontSize: '50px',

    
    fontWeight: '800',
    letterSpacing: '12px',
    

  }
  ,
  
  headtext: {
    color:'#4f4f4f',
    fontFamily: 'Open Sans , sans-seri8f',
    fontWeight:'400',
    fontSize:'14px',
    
    textAlign: 'justify',
    lineHeight: 1.8,
    letterSpacing: '0.01071em'
  },

 
  category: {
    flex: 1,
    display: 'flex',
    gap: '20px',
    justifyContent:'flex-start',
    
    // other styles...
  },

 


carousel: {
  width: '100%',
  height: '70vh',

},
navButton: {
  backgroundColor: 'white',
  borderRadius: '50%', 
  '& > *': { // This targets the child elements of the navButton, which are the arrows
      color: 'black'
  }
},
indicatorContainer: {
  position: 'absolute',
  bottom: '5%',
  left: '50%',
  transform: 'translateX(-50%)',
  color:'red',
  zIndex:'1000'
},
postItemContainer: {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  
},
postImage: {
  width: '100%',
  height: '70vh',
  objectFit: 'cover'
},
postInfo: {
  position: 'absolute',
  top: '50%',
  left: '10%',
  color: 'white',
  width: '300px',
  
},
checkButton: {
  marginTop: '10px',

},
carouselContainer: {

  marginBottom: '30px'
},
carouselTextWrapper: {
  backgroundColor: 'red',
  borderRadius: 8,
  padding: '10px',
  boxShadow: '0px 4px 6px #00000055',
  display: 'inline-block',
  position: 'absolute',
  top: '40%',
  left: '10%',
  color: 'white',
  width: '40%',
},

carouseltitletext:{
  color:'white',
  boxShadow: 'none',
     fontFamily: 'Open Sans , sans-serif',
    fontWeight:'400',
    fontSize:'14px',
    marginBottom:'5px',
    letterSpacing: '0.03em',
    paddingTop:'10px',
},
cardActions: {
  padding: '0 0px 8px 0px',
  display: 'flex',
  justifyContent: 'space-between',
},
trennen:{
height:'50px',
borderBottom: '1px solid #bcbcbc',
width: '100%',
margin:'10px 0px 60px 0px'
},
darkGradient: {
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to right, rgb(0 0 0 / 99%), transparent)',
    pointerEvents: 'none', // Ensure overlay doesn't interfere with interactions
  }},
 

button: {
    margin: theme.spacing(1),
    // Additional button styles...
},
imageDisplay: {
    flex: 1,
    minHeight: '300px',
    
    backgroundPosition: 'center',
    backgroundColor: '#e7e7e7',
    borderRadius:'20px', // Default color
    backgroundSize: 'cover',
    overflow: 'hidden', 
    boxSizing: 'border-box',
},
homeMain: {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: '5%',
},


categoryList:{
  minHeight: '300px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: '#f1efe9',
    borderRadius:'20px',
    flex: 1,

        alignItems: 'center', // Vertically aligns children in the middle
        justifyContent: 'flex-start',
},
List:{
  padding:'20px',
 
  display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
},
Listbutton: {
  color: '#949191 !important',
  fontFamily: 'Open Sans , sans-serif !important',
  fontWeight:'500 !important',
  fontSize:'16px !important',
  backgroundColor: 'transparent !important', // Remove background
  boxShadow: 'none !important', 
  '&:hover': {
    color: '#1a866b !important',
    textDecoration: 'underline !important',
    backgroundColor: 'transparent !important', // Remove background
  boxShadow: 'none !important', 

},
appBarSearch: {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),  // for spacing if required
  // ... any other styles you might have for appBarSearch ...
},
appBarMain:{
margin:'30px'
},
pagination: {
  borderRadius: 4,
  marginTop: '1rem',
  padding: '16px',
  backgroundColor:'#f8f7f4 !important',
},
paper: {
  padding: theme.spacing(2),
  boxShadow: 'none',
  borderRadius: '12px',
  
},



},

}));