import React from 'react'
import {  Container } from '@material-ui/core';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import { HashRouter as Router, Route, Switch,Redirect  } from 'react-router-dom';

import Home from './components/Home/Home';
import Contact from './components/Contact/Contact';
import About from './components/Aboutus/About';
import Articels from './components/Articels/Articels';

import NewsletterMain from './components/Newsletter/NewsletterMain'

import SearchResults from './components/Home/SearchResults';

import Auth from './components/Auth/Auth';
import Admin from './components/Admin/Admin';
import Legal from './components/Legal/Legal';
import NotFoundPage from './components/NotFoundPage/NotFoundPage';

import PostDetails from './components/PostDetails/PostDetails';
import PostsByTags from './components/PostsbyTags/PostsbyTags';
import { QueryClient, QueryClientProvider } from 'react-query';
import CookieConsentPanel from './components/CookieConsentPanel'; // Import the component
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative', // Stellt sicher, dass der Root-Container relativ positioniert ist
    minHeight: '100vh',  // Gesamthöhe des Viewports
  },
  content: {
    paddingBottom: theme.spacing(10), // Fügt am unteren Rand des Inhaltsbereichs Platz hinzu
  },
  Navbar:{
    paddingLeft: '24px',
    paddingRight: '24px'  
  },
  footer: {
    position: 'absolute', // Absolut positioniert relativ zum Root-Container
    bottom: 0,             // Am unteren Rand des Root-Containers
    width: '100%', 
    paddingLeft: '24px',
    paddingRight: '24px'        // Volle Breite
    // ...weitere Footer-Stile...
  },
  // ...andere Stile...
}));

const App = () => {
  const classes = useStyles();

const admin =JSON.parse(localStorage.getItem('profile')); 
  return (
    <Router>
    <div className={classes.root}>
      <Navbar className={classes.Navbar} />
      <Container maxWidth="lg" className={classes.content}>
        <CookieConsentPanel /> 
        <Switch>
          {/* <Route path="/" exact component={()=> <Redirect to="/posts" />} />
          <Route path="/posts" exact component={Home} /> */}
          <Route path="/" exact component={Home} />
          <Route path="/articels" exact component={Articels} />
          <Route path="/contact" exact component={Contact} />

          <Route path="/about-us" exact component={About} />
          
          <Route path="/posts/search" exact component={SearchResults} />
          <Route path="/posts/:id"  component={PostDetails} />

          <Route path="/tags/:tagName" component={PostsByTags} />

          <Route path="/horizonthausadmin/auth" exact component={Admin} />

          <Route path="/horizonthausadmin/auth/secret" exact component={() => (!admin ? <Auth /> : <Redirect to="/posts"/>)} />

          <Route path="/newsletter" exact component={NewsletterMain} />

          <Route path="/legal" exact component={Legal} />
          <Route path="" component={NotFoundPage} />
          </Switch>
        </Container>
        <Footer className={classes.footer} />
      </div>
    </Router>
  )
}

export default App
