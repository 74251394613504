// CustomSpinner.js
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  spinner: {
    position: 'relative',
    width: '110px',
    height: '80px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'end',
    margin: '50px auto',
  },
  block: {
    width: '25px',
    height: '40px',
    backgroundColor: 'black',
    marginBottom: '5px',
    borderRadius: '7px',
  },
  tall: {
    height: '30px',
  },
  jump: {
    animation: '$jump 0.5s ease-in-out infinite alternate',
  },
  "@keyframes jump": {
    "0%": { transform: 'translateY(0)' },
    "100%": { transform: 'translateY(-10px)' },
  }
});

const CustomSpinner = () => {
  const classes = useStyles();

  return (
    <div className={classes.spinner}>
      <div>
        <div className={`${classes.block} ${classes.tall}`}></div>
        <div className={`${classes.block} ${classes.tall}`}></div>
      </div>
      <div className={`${classes.block} ${classes.jump}`}></div>
      <div>
        <div className={`${classes.block} ${classes.tall}`}></div>
        <div className={`${classes.block} ${classes.tall}`}></div>
      </div>
      <div className={`${classes.block} ${classes.jump}`}></div>
    </div>
  );
};

export default CustomSpinner;
