// styles.js
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
   
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '20px',
   boxShadow:'none', 
   backgroundColor: 'transparent',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  inputField: {
    // Override the default input field styles as necessary
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#19866c', // Custom color for the label on focus
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)', // Default border color
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)', // Border color on hover (optional: you can change this)
      },
      '&.Mui-focused fieldset': {
        borderColor: '#19866c', // Custom color for the border on focus
      },
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: 'black',
    color:'white',
    

    border: 'none',
   // Rounded corners for the button
    '&:hover': {
      backgroundColor: '#19866c !important', // Button hover color
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
},
subtitle: {
  fontSize: '1.5rem',
  fontFamily: '"Open Sans", sans-serif', // Ensure the font is correctly referenced
  fontWeight: 700,
  lineHeight: 1.334,
  letterSpacing: '0em',
  paddingBottom: '10px',
},
subtext: {
  color:'#4f4f4f',
    fontFamily: 'Open Sans , sans-seri8f',
    fontWeight:'400',
    fontSize:'14px',
    
    textAlign: 'justify',
    
    letterSpacing: '0.01071em'
},
formSide: {
    // styles for the form side
},
newsletterImage: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius:'20px'
},
successmessage:{
    color:'#368339',
    fontFamily: 'Open Sans , sans-seri8f',
    fontWeight:'400',
    fontSize:'14px',
    marginTop: '10px',

},
errormessage:{
  color:'#c53123',
  fontFamily: 'Open Sans , sans-seri8f',
  fontWeight:'400',
  fontSize:'14px',
  marginTop: '10px',

}
  // ... other styles that you might want to include or override
}));
