import { makeStyles } from '@material-ui/core/styles';



export default makeStyles((theme) => ({
    // ... deine anderen Stile ...
    
    paper: {
      padding: theme.spacing(3),
      margin: theme.spacing(3, 0),
      backgroundColor: '#f8f7f4', // Hintergrundfarbe wie in deinem Hauptstil
    },
    headtext: {
      color: '#4f4f4f',
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: '400',
      fontSize: '16px',
      textAlign: 'justify',
      lineHeight: 1.8,
      letterSpacing: '0.01071em',
      marginBottom: theme.spacing(2),
    },
    card: {
        // Hier kannst du Stile aus deinem Post verwenden, z.B.:
        borderRadius: '20px',
        boxShadow: 'none',
        backgroundColor: '#ff000000', // oder eine andere Farbe nach Wahl
        transition: 'background 100ms ease-out 0s',
        '&:hover': {
          backgroundColor: '#dfdfdf', // leichte graue Hintergrundfarbe beim Hover
        },
        // Füge hier zusätzliche Stile hinzu, die zu deinem Design passen
      },
      title: {
        fontFamily: 'Open Sans , sans-serif',
        fontWeight: '400',
        // Hier kannst du Stile aus deinem Post verwenden
        // Zusätzlich könntest du die Schriftgröße und andere Stile anpassen, um dem Hochkant-Text auf dem Bild zu ähneln
      },
      titletext: {
        fontFamily: 'Open Sans , sans-serif',
        fontWeight: '200',
        color: '#8b8b8b',
        // ...weitere Stile aus deinem Post
      },
    // Hier könntest du weitere spezifische Stile hinzufügen, falls nötig
  }));
  