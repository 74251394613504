import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  media: {
    borderRadius: '20px',
    objectFit: 'cover',
    width: '100%',
    maxHeight: '400px',

  },
 paper:{
  padding: '20px', 
  borderRadius: '15px', 
  backgroundColor: 'transparent' ,
  [theme.breakpoints.down('sm')]: {
    padding: '0px', 
  },
 },
  card: {
   
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
  },
  section: {
    borderRadius: '20px',
    margin: '10px',
    flex: 1,
    maxWidth:'650px'
  },
  imageSection: {
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  recommendedPosts: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  loadingPaper: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', borderRadius: '15px', height: '39vh',
  },
  title: {
    fontFamily: 'Open Sans , sans-serif',
    fontWeight:'800',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2, // Display up to 2 lines (camelCased)
    WebkitBoxOrient: 'vertical',
    [theme.breakpoints.down('sm')]: {
      fontSize:'2rem',
  },
  },

cratedby:{
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 500,
    fontSize: '13px',
    marginTop: '25px',
},
fotoby:{
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 500,
    fontSize: '13px',
    marginTop: '5px',
}
,
tags:{
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 500,
    fontSize: '17px',
    marginTop: '10px',
    color: '#1a866b !important',
 
    textDecoration: 'underline !important'
},
titletext:{
    marginBottom: '15px',
    marginTop: '15px',
    fontSize:'20px',
    textAlign: 'justify',
    hyphens: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize:'14px',
  },
},

message: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    color: '#212121',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '2',
    WebkitBoxOrient: 'vertical',
    textAlign: 'justify',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',

    '&::first-letter': {
      fontSize: '200%',
   
      // Other styles if needed
    },
    [theme.breakpoints.down('sm')]: {
      fontSize:'14px',
  },
  },
  messageContent: {
    margin: 'auto',
    width: '70%',
    marginTop: '40px',

    '& p:first-of-type::first-letter': {
      float: 'left',
      fontSize: '84px',
      lineHeight: '60px',
      paddingTop: '3px',
      paddingRight: '5px',
      marginLeft: '-4px',
      fontFamily: 'BarnaStencil, sans-serif',
      fontWeight: '900',
      // Other styles if needed
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: 'white', // or any color you prefer
    borderRadius: theme.shape.borderRadius,
    '& .MuiOutlinedInput-input': {
      color: 'black', // Adjust text color
    },
    '& .MuiInputLabel-root': {
      color: 'black', // Adjust label color
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black', // Adjust border color
    },
    '&:hover .MuiOutlinedInput-input': {
      color: 'gray', // Adjust hover text color
    },
    '&:hover .MuiInputLabel-root': {
      color: 'gray', // Adjust hover label color
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'gray', // Adjust hover border color
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
      color: 'black', // Adjust focused text color
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'black', // Adjust focused label color
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black', // Adjust focused border color
    },
  },
  

}));
