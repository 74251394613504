import React, { useState, useEffect, useRef } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Typography, Avatar, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import InstagramIcon from '@material-ui/icons/Instagram';
import PinterestIcon from '@material-ui/icons/Pinterest';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { TextField, InputAdornment } from '@material-ui/core';

import { Menu, MenuItem } from '@material-ui/core';
import useStyles from './styles';
import sitelogo from '../../images/pridelogo2.png';
import { getPostsBySearch } from '../../actions/posts';

const Navbar = () => {
    // ... existing states and functions
    const classes = useStyles();
    const [admin, setAdmin] = useState(JSON.parse(localStorage.getItem('profile')));
    const [search, setSearch] = useState('');
    const [tags, setTags] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, setDrawerOpen] = useState(false);

    const searchRef = useRef();
    const logout = () => {
        dispatch({ type: 'LOGOUT' });
        history.push('/');
        setAdmin(null);
        window.location.reload();
    };

    useEffect(() => {
        const token = admin?.token;
        if (token) {
            const decodedToken = decode(token);
            if (decodedToken.exp * 1000 < new Date().getTime()) logout();
        }
        setAdmin(JSON.parse(localStorage.getItem('profile')));
    }, [location]);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const categories = [
     
        { name: ' Alle Rubiken', path: '/articels', formatPath: false },

        { name: 'Wissenschaft', path: '/tags/wissenschaft', formatPath: true },
        { name: 'Kultur & Kunst', path: '/tags/kultur-kunst', formatPath: true },
        { name: 'Gesellschaft', path: '/tags/gesellschaft' , formatPath: true},
        { name: 'Geschichte', path: '/tags/geschichte', formatPath: true },
        { name: 'Filme & Serien', path: '/tags/filme-serien', formatPath: true },
        { name: 'Essen & Reisen', path: '/tags/essen-reisen', formatPath: true },
      ];
   // Function to format category name for the URL
   const formatPathName = (name) => {
    return name.toLowerCase().replace(/\s&\s/g, '-').replace(/\s/g, '');
  };

const [openMenu, setOpenMenu] = useState(false);

const handleMenuClick = (event) => {
  setAnchorEl(event.currentTarget);
  setOpenMenu(true);
};

const handleMenuClose = () => {
  setAnchorEl(null);
  setOpenMenu(false);
};

const spotifyIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 168 168">
      <path fill="black" d="M84,0C37.7,0,0,37.7,0,84s37.7,84,84,84s84-37.7,84-84S130.3,0,84,0z M121.9,125.9
        c-1.6,2.6-4.8,3.4-7.4,1.8c-19.4-11.8-43.9-14.4-72.8-7.7c-2.8,0.7-5.7-0.9-6.4-3.7c-0.7-2.8,0.9-5.7,3.7-6.4
        c31.4-7.3,58.5-4.2,80.4,9.1C122.6,119.9,123.5,123.2,121.9,125.9z M133.2,100.2c-2,3.3-6.1,4.3-9.4,2.3
        c-22.6-14.1-58.2-17.2-91.1-9.1c-3.6,0.9-7.3-1.2-8.2-4.8c-0.9-3.6,1.2-7.3,4.8-8.2c36.4-9,76.2-5.2,102.3,10.7
        C133.9,92.5,135.2,96.9,133.2,100.2z M137.7,74.5c-26.7-16.7-69.3-21-102.4-11.8c-4.3,1.1-8.8-1.6-9.9-5.9
        c-1.1-4.3,1.6-8.8,5.9-9.9c36.9-10.2,84.1-5.2,115.3,13.7c2.7,1.6,3.7,5.1,2.1,7.8C145.1,73.7,140.4,76.1,137.7,74.5z"/>
    </svg>
  );

    // Handlers
    const searchPost = () => {
        if (search.trim() || (tags && tags.length)) {
            dispatch(getPostsBySearch({ search, tags: tags.join(',') }));
            history.push(`/posts/search?searchQuery=${search || 'none'}&tags=${tags.join(',')}`);
            setSearch('');
        } else {
            history.push('/');
        }
    };

    const handleKeyPress = (e) => { 
        if (e.key === 'Enter') {
            searchPost(); 
        } 
    };

    const drawer = (
        <div>
            <List>
                <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
                    <ListItemText className={classes.navText} primary="Home" />
                </ListItem>
               
                {categories.map((category, index) => (
                    <ListItem button key={index} component={Link} to={category.path} onClick={handleDrawerToggle}>
                        <ListItemText className={classes.navText}   primary={category.name} />
                    </ListItem>
                ))}
                {/* Additional links and actions */}
                <ListItem button component={Link} to="/about-us" onClick={handleDrawerToggle}>
                    <ListItemText className={classes.navText}  primary="About Us" />
                </ListItem>
                <ListItem button component={Link} to="/contact" onClick={handleDrawerToggle}>
                    <ListItemText className={classes.navText}  primary="Contact" />
                </ListItem>
                {/* Search and other actions can be added here */}
                {admin ? (
                        <div className={classes.profile}>
                            <Avatar className={classes.purple} alt={admin.result.name} src={admin.result.imageUrl}>{admin.result.name.charAt(0)}</Avatar>
                            <Typography className={classes.adminName} variant="h6">{admin.result.name}</Typography>
                            <Button className={classes.logout} onClick={logout}>Logout<ExitToAppSharpIcon/></Button>
                        </div>    
                    ) : null} 
  
                    <div className={classes.rightContainer}>
            
       
       {/* Instagram Icon */}
<IconButton component="a" href="https://www.instagram.com/horizonthaus/" target="_blank" rel="noopener noreferrer">
  <InstagramIcon style={{ color: 'black' }} />
</IconButton>

{/* Pinterest Icon */}
<IconButton component="a" href="https://www.pinterest.at/horizonthaus/" target="_blank" rel="noopener noreferrer">
  <PinterestIcon style={{ color: 'black' }} />
</IconButton>

{/* Spotify Icon */}
<IconButton component="a" href="https://open.spotify.com/user/31rn6gjvjj76jgbxlqcn26lb2ob4" target="_blank" rel="noopener noreferrer">
{spotifyIcon}
</IconButton>
      </div>
            </List>
        </div>
    );

    return (
        <AppBar className={classes.appBar} position="static">
            <Toolbar className={classes.toolbar}>
           
                 {isMobile && (
                    <>
 
              
                     <div className={classes.centerLogoMobile}>
                     <Link to="/">
                         <img className={classes.mobileLogo} src={sitelogo} alt="icon"  />
                     </Link>
                 </div>
                 <div className={classes.searchWrapper}>
                             {/* Removed IconButton that toggled search */}
                             <div className={classes.searchBarOpen} ref={searchRef}>
                             <TextField
                             name="search" 
                             variant="outlined"
                             placeholder="Suchen"
                             className={classes.searchInput}
                             value={search}
                             onKeyPress={handleKeyPress}
                             onChange={(e) => setSearch(e.target.value)}
                             InputProps={{
                             startAdornment: (
                             <InputAdornment position="start">
                             <IconButton
                             type="submit"
                             className={classes.iconButton}
                             aria-label="search"
                             onClick={searchPost}
                             >
                             <SearchIcon />
                             </IconButton>
                             </InputAdornment>
                             ),
                             }}
                     />
                                 
                         </div>
                     </div>
               </>
               )}
                {isMobile && (
                    <>
                        <IconButton
                            edge="start"
                            color="black"
                            style={{
                                color: 'black', 
                                backgroundColor: '#ebfe36', 
                                borderRadius: '10px',
                                padding: '7px',
                                border: 'solid 1px #0000002b'
                            }}
                            aria-label="menu"
                            onClick={handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
                            {drawer}
                        </Drawer>
                    </>
                )}

                {isMobile ? (
                    <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
                        {drawer}
                    </Drawer>
                ) : (
                    <>
                            <div className={classes.leftContainer}>
                            
                        <Link to="/">
                            <img className={classes.image} src={sitelogo} alt="icon" height="auto" width="70px" style={{paddingRight: '10px'}} />
                        </Link>
                        
                  
                  <Button style={{ textTransform: 'none'}} component={Link} to="/">Home</Button>
                  <Button
          endIcon={<ArrowDropDownIcon />}
          style={{ textTransform: 'none' }}
          aria-controls="basic-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
        >
          Rubriken
        </Button>
        <Menu
id="basic-menu"
anchorEl={anchorEl}
keepMounted
open={Boolean(anchorEl)}
onClose={handleMenuClose}
getContentAnchorEl={null}
anchorOrigin={{
  vertical: 'bottom', // Align the top of the menu with the bottom of the anchorEl
  horizontal: 'left', // Align the left of the menu with the left of the anchorEl
}}
transformOrigin={{
  vertical: 'top', // Transform the menu from its top
  horizontal: 'left', // Transform the menu from its left
}}
>
          {categories.map((category, index) => (
         
              <MenuItem 
                key={index} 
                component={Link} 
                // Check if the path needs to be formatted
                to={category.formatPath ? `/tags/${formatPathName(category.name)}` : category.path}
                onClick={handleMenuClose}
              >
                {category.name}
              </MenuItem>
          
          ))}
        </Menu>


                  <Button  style={{ textTransform: 'none'}}component={Link} to="/about-us">About Us</Button>
                  <Button  style={{ textTransform: 'none'}}component={Link} to="/contact">Contact</Button>
                        {admin ? (
                      <div className={classes.profile}>
                          <Avatar className={classes.purple} alt={admin.result.name} src={admin.result.imageUrl}>{admin.result.name.charAt(0)}</Avatar>
                          <Typography className={classes.adminName} variant="h6">{admin.result.name}</Typography>
                          <Button className={classes.logout} onClick={logout}>Logout<ExitToAppSharpIcon/></Button>
                      </div>    
                  ) : null} 
              </div>

                        
                            

                         <div className={classes.rightContainer}>
            
            <div className={classes.searchWrapper}>
          {/* Removed IconButton that toggled search */}
          <div className={classes.searchBarOpen} ref={searchRef}>
          <TextField
name="search" 
variant="outlined"
placeholder="Suchen"
className={classes.searchInput}
value={search}
onKeyPress={handleKeyPress}
onChange={(e) => setSearch(e.target.value)}
InputProps={{
startAdornment: (
<InputAdornment position="start">
<IconButton
type="submit"
className={classes.iconButton}
aria-label="search"
onClick={searchPost}
>
<SearchIcon />
</IconButton>
</InputAdornment>
),
}}
/>
             
          </div>
      </div>
       {/* Instagram Icon */}
<IconButton component="a" href="https://www.instagram.com/horizonthaus/" target="_blank" rel="noopener noreferrer">
  <InstagramIcon style={{ color: 'black' }} />
</IconButton>

{/* Pinterest Icon */}
<IconButton component="a" href="https://www.pinterest.at/horizonthaus/" target="_blank" rel="noopener noreferrer">
  <PinterestIcon style={{ color: 'black' }} />
</IconButton>

{/* Spotify Icon */}
<IconButton component="a" href="https://open.spotify.com/user/31rn6gjvjj76jgbxlqcn26lb2ob4" target="_blank" rel="noopener noreferrer">
{spotifyIcon}
</IconButton>
      </div>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};


export default Navbar;
