import { FETCH_ALL, FETCH_POST, FETCH_BY_SEARCH, CREATE, UPDATE, DELETE, START_LOADING, END_LOADING , FETCH_BY_TAG} from '../constants/actionTypes';
import * as api from '../api/index.js';

export const getPost = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING });
  try {
    const { data } = await api.fetchPost(id);
    dispatch({ type: FETCH_POST, payload: data });
  } catch (error) {
    console.error("Error fetching the post:", error.message);
  } finally {
    dispatch({ type: END_LOADING });
  }
};

export const getPosts = () => async (dispatch) => {
  dispatch({ type: START_LOADING });
  try {
    const { data } = await api.fetchPosts();
    dispatch({ type: FETCH_ALL, payload: data });
  } catch (error) {
    console.error("Error fetching all posts:", error.message);
  } finally {
    dispatch({ type: END_LOADING });
  }
};


export const getPostsBySearch = (searchQuery) => async (dispatch) => {
  dispatch({ type: START_LOADING });
  try {
    const { data: { data } } = await api.fetchPostsBySearch(searchQuery);
    dispatch({ type: FETCH_BY_SEARCH, payload: data });
  } catch (error) {
    console.error("Error fetching posts by search:", error);
  } finally {
    dispatch({ type: END_LOADING });
  }
};
export const getPostsByTag = (tag) => async (dispatch) => {
  dispatch({ type: START_LOADING });
  try {
      console.log(`Making API call to /tags/${tag}`);
      const { data } = await api.fetchPostsByTag(tag);
      console.log('Received data:', data);
      dispatch({ type: FETCH_BY_TAG, payload: data });
  } catch (error) {
      console.error("Error fetching posts by tag:", error);
  } finally {
      dispatch({ type: END_LOADING });
  }
};


export const createPost = (post) => async (dispatch) => {
  dispatch({ type: START_LOADING });
  try {
    const { data } = await api.createPost(post);
    dispatch({ type: CREATE, payload: data });
  } catch (error) {
    console.error("Error creating post:", error.message);
  } finally {
    dispatch({ type: END_LOADING });
  }
};

export const updatePost = (id, post) => async (dispatch) => {
  dispatch({ type: START_LOADING });

  try {
    const { data } = await api.updatePost(id, post);
    dispatch({ type: UPDATE, payload: data });
    console.log(`Dispatching update for post ID: ${id}`, post);

  } catch (error) {
    console.error("Error updating post:", error);
  } finally {
    dispatch({ type: END_LOADING });
  }
};

export const deletePost = (id) => async (dispatch) => {
  dispatch({ type: START_LOADING });
  try {
    await api.deletePost(id);
    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.error("Error deleting post:", error);
  } finally {
    dispatch({ type: END_LOADING });
  }
};


