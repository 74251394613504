import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({

 
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: '24px',
    marginTop: '40px',
    borderRadius:'20px'
  },
  topContainer:{
    marginBottom:'50px'
  },
  logoContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
      textAlign: 'left',
    
    marginBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '300px' // Height for mobile screens
    },
    
  },
  logo: {
    maxWidth: '50px',
    paddingRight: '20px', // Adjust as needed
  },
  navLinksContainer: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'end',
      marginBottom: 0,
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      justifyContent: 'left',
      alignItems: 'center'
    }
  },
  navLink: {
    margin: theme.spacing(0, 2),
    color: '#000',
    fontSize: '14px',
    fontFamily:'"Open Sans", sans-serif',
   
    fontWeight: '700',
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px'
    }
  },
  socialAndLegalContainer: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      justifyContent: 'left',
      alignItems: 'center'
    }
  },
  iconButton: {
    
    color: '#000',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  legalText: {
    color: '#000',
    display: 'inline-block',
    marginRight: theme.spacing(2),
    fontSize: '13px',
    fontFamily:'"Open Sans", sans-serif',
    color: '#888',
    fontWeight: '400',
    
  },
  legalLink: {
    margin: theme.spacing(0, 1),
    fontSize: '13px',
    fontFamily:'"Open Sans", sans-serif',
    color: '#888',
    fontWeight: '400',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  // ... Add other styles if needed
}));
