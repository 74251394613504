import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { deletePost } from '../../../actions/posts';
import useStyles from './styles';

const Post = ({ post, setCurrentId, index }) => {
  const classes = useStyles({ index });
  const dispatch = useDispatch();
  const history = useHistory();
  const admin = JSON.parse(localStorage.getItem('profile'));
  moment.locale('de'); 
  

  const openPost = () => history.push(`/posts/${post._id}`);

  return (
    <Card className={`${classes.card} ${index % 2 === 0 ? classes.alternatePost : ''}`}>
      <CardMedia
        className={`${classes.media} ${index % 2 === 0 ? classes.evenPostMedia : classes.oddPostMedia}`}
        image={post.selectedFile || 'DEFAULT_IMAGE_LINK'}
        title={post.title}
      />

      <div className={classes.detailscard}>
        <div className={classes.overlay}>
          <Typography className={classes.tag} variant="body2">
            {post.tags.map(tag => <span key={tag}>#{tag} </span>)}
          </Typography>
        </div>

        {admin && (
          <div className={classes.overlay2}>
            <Button style={{ color: 'white' }} size="small" onClick={() => {
    console.log("Edit button clicked, setting currentId to:", post._id);
    setCurrentId(post._id);
}}>
    <MoreHorizIcon fontSize="default" />
</Button>
          </div>
        )}

        <div className={classes.details}>
          <Typography className={classes.date} variant="body2">{moment(post.createdAt).format('MMMM/YYYY')}</Typography>
        </div>

        <Typography className={classes.title} gutterBottom variant="h5">{post.title}</Typography>

        <CardContent className={classes.cardContent}>
          <Typography variant="body2" className={classes.titletext}>{post.titletext}</Typography>
        </CardContent>

        {admin ? (
          <CardActions className={classes.cardActions}>
            <Button size="small" onClick={() => dispatch(deletePost(post._id))}>
              <DeleteIcon fontSize="small" /> Delete
            </Button>
          </CardActions>
        ) : (
          <CardActions className={classes.cardActions}>
            <Button size="small" className={classes.cardAction} onClick={openPost}>
              weiter lesen <ArrowForwardIcon />
            </Button>
          </CardActions>
        )}
      </div>
    </Card>
  );
};

export default Post;
