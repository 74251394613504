import { AUTH } from '../constants/actionTypes';

import * as api from '../api/index.js';




export const signin = (formData, history) => async (dispatch) => {
    try{
       const { data } = await api.signIn(formData);
       
       // Store the JWT token in local storage
       localStorage.setItem('profile', data.token);

       dispatch({ type: AUTH, data});
       history.push('/');
    } catch (error) {
        console.log(error);
    }
};

export const signup = (formData, history) => async (dispatch) => {
    try{
        const { data } = await api.signUp(formData);

        // Store the JWT token in local storage
        localStorage.setItem('profile', data.token);

        dispatch({ type: AUTH, data});
        history.push('/');
    } catch (error) {
        console.log(error);
    }
};
