import React from 'react';
import { Grid, Paper, Typography, Card, CardContent } from '@material-ui/core';
import useStyles from './styles';

const About = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom className={classes.title}>
            Über HorizontHaus
          </Typography>
          <Typography variant="body1" className={classes.headtext} paragraph>
        Willkommen bei HorizontHaus – Ihrem Portal in eine Welt voller Erkenntnisse und Entdeckungen. 
        In der heutigen Zeit, in der Information überall und jederzeit verfügbar ist, setzen wir auf Qualität und Tiefgang. 
        HorizontHaus ist mehr als nur eine Website; es ist ein Treffpunkt für Wissenssuchende, die über den Tellerrand hinausschauen wollen.
      </Typography>
        </Grid>

        {/* Jede Card sollte in ihrer eigenen Grid item Komponente sein */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Unsere Mission
              </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Unsere Mission ist es, Ihnen nicht nur Informationen, sondern Einblicke und Verständnis zu bieten. Jeden Monat kuratieren wir eine Auswahl an Artikeln, die nicht nur informieren, sondern inspirieren...
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Unsere Themen
              </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Von den neuesten wissenschaftlichen Durchbrüchen bis zu den untold Geschichten der Kunst, von gesellschaftlichen Diskursen bis zu den verborgenen Schätzen der Geschichte, von tiefgehenden Film- und Serienanalysen bis hin zu den kulturellen Besonderheiten von Essen und Reisen – HorizontHaus deckt ein breites Spektrum an Interessen ab. Unsere Artikel sind so vielfältig wie unsere Leserschaft.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Unsere Versprechen
              </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Bei HorizontHaus verpflichten wir uns zur Integrität und Authentizität. Jeder Artikel durchläuft einen sorgfältigen Prozess der Faktüberprüfung und redaktionellen Prüfung, um sicherzustellen, dass Sie verlässliche und anregende Inhalte erhalten. Wir sind stolz darauf, eine Plattform zu bieten, die frei von Sensationslust und Clickbait ist – ein ruhiger Hafen im stürmischen Meer des Internets.
              </Typography>
            </CardContent>
          </Card>
        </Grid>


         <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Ihre Reise mit uns
              </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Als Mitglied von HorizontHaus sind Sie Teil einer Community, die Wert auf Qualität und Dauerhaftigkeit legt. Mit jedem Artikel, den Sie lesen, erweitern Sie Ihren Horizont ein Stück weiter. Lassen Sie sich von uns monatlich neue Welten eröffnen, die Ihre Fantasie beflügeln und Ihren Wissensdurst stillen.

Entdecken Sie mit HorizontHaus – wo Neugierde auf Wissen trifft.                </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Füge weitere Karten hinzu, wenn nötig */}
      </Grid>
    </Paper>
  );
};

export default About;


 