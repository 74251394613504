import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import useStyles from './styles';
import FileBase from 'react-file-base64';
import { useDispatch , useSelector } from 'react-redux';
import { createPost, updatePost } from '../../actions/posts';
import { Editor, EditorState } from 'draft-js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



const Form = ({ currentId, setCurrentId }) => {
    
const admin = JSON.parse(localStorage.getItem('profile'));

const [postData, setPostData] = useState({  title: '',titletext: '', message: '', tags: '', imagetext: '', selectedFile: '' });   
const post = useSelector((state) => currentId ? state.posts.posts.find((p) => p._id === currentId) : null);
//const post = useSelector((state) => Array.isArray(state.posts) && currentId ? state.posts.find((p) => p._id === currentId) : null);
//const post = useSelector((state) => currentId ? state.post : null);

const [editorState, setEditorState] = useState(EditorState.createEmpty());

const [imagePreview, setImagePreview] = useState(null);
const classes = useStyles();
const dispatch = useDispatch();
const [editorContent, setEditorContent] = useState('');



/* useEffect(() => {
    if(post) {
        setPostData(post);
        console.log('postData after setting:', postData);
    }
}, [post, postData]);
 */
const allPosts = useSelector((state) => state.posts);
console.log('All posts:', allPosts);




const [quillContent, setQuillContent] = useState('');

// On Component Mount or when 'post' changes
useEffect(() => {
    if (post) {
        setPostData(post); // Set all post data
        setQuillContent(post.message); // Set only message for Quill
        
    }
}, [post]);



const handleQuillChange = (content) => {
    setQuillContent(content);
};


 
const clear = () => {
    setCurrentId(0);
    setPostData({ title: '', titletext: '', message: '', imagetext: '', tags: '', selectedFile: '' });
    setQuillContent(''); // Reset the content of React Quill editor
};


const handleSubmit = useCallback((e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', postData.title);
    formData.append('titletext', postData.titletext);
    formData.append('message', quillContent); // Update message from Quill content
    formData.append('tags', postData.tags);
    formData.append('imagetext', postData.imagetext);
    if (postData.selectedFile) {
        formData.append('image', postData.selectedFile); // Append file
    }
    
    if (currentId === 0) {
        dispatch(createPost(formData)); // Dispatch formData instead of JSON
        clear();
    } else {
        dispatch(updatePost(currentId, formData)); // Dispatch formData for update
        clear();
    }
}, [currentId, postData, quillContent, dispatch, clear]);




if(!admin?.result?.name){
    return(
        <Paper className={classes.paper} >
        <Typography  variant="h6" align="center">
            Please Sign in to create your own post for Horizonthaus Magazine
        </Typography>
        </Paper>
    )
};

    return (
        
        <div>
           
            
            <Paper className={classes.paper}>
                <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`}onSubmit={handleSubmit}>
                <Typography className={classes.formHead} variant="h6">{currentId && post ? `Editing "${post.title}"` : 'Creating an Article'}</Typography>
                    <TextField   name="title" variant="outlined" label="Title" fullWidth value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value })} />
                    <TextField   name="titletext" variant="outlined" label="Titletext" fullWidth value={postData.titletext} onChange={(e) => setPostData({ ...postData, titletext: e.target.value })} />
                    <ReactQuill 
    className={classes.formquill} 
    value={quillContent} 
    onChange={handleQuillChange} 
/>
                    <TextField   name="tags" variant="outlined" label="Tags (coma separated)" fullWidth value={postData.tags} onChange={(e) => setPostData({ ...postData, tags: e.target.value.split(',') })} />
                    <TextField   name="imagetext" variant="outlined" label="Imagetext" fullWidth value={postData.imagetext} onChange={(e) => setPostData({ ...postData, imagetext: e.target.value })} />

                    <div className={classes.fileInput}>
    <input 
        type="file" 
        onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
                setPostData({ ...postData, selectedFile: e.target.files[0] });
                setImagePreview(URL.createObjectURL(e.target.files[0]));
            }
        }}
    />
</div>



{imagePreview && (
    <img src={imagePreview} alt="Selected Preview" width="200px" />
)}

                    <Button className={classes.buttonSubmit} variant="contained"  size="large" type="submit" fullWidth>Submit</Button>
                    <Button className={classes.buttonClear}  variant="contained" size="small" onClick={clear} fullWidth>Clear</Button>
                </form>
            </Paper>
           
        </div>
    )
}

export default Form;
