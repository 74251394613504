// styles.js
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
   
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '20px',
   boxShadow:'none', 
   backgroundColor: 'transparent',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
},
errortitle: {
  fontSize: '3.5rem',
  fontFamily: '"Open Sans", sans-serif', // Ensure the font is correctly referenced
  fontWeight: 700,
  lineHeight: 1.334,
  letterSpacing: '0em',
  paddingBottom: '10px',
},
errortext: {
  color:'#4f4f4f',
    fontFamily: 'Open Sans , sans-seri8f',
    fontWeight:'400',
    fontSize:'15px',
    
    textAlign: 'justify',
    
    letterSpacing: '0.01071em'
},

errorImage: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius:'20px'
},
homeBack :{
  marginTop: '20px',
    padding: '10px',
    color: 'black',
    borderRadius: '12px',
    backgroundColor: 'rgb(235, 254, 56)',
    fontFamily: 'Open Sans',
    fontWeight: '600'
}
  // ... other styles that you might want to include or override
}));
