import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  appBar: {
    borderRadius: 15,
    margin: '30px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    boxShadow: '0 0 0',
    backgroundColor: '#f8f7f4',

   
  },
  heading: {
    color: 'rgba(0,0,0)',
    fontSize:'16px',
    marginLeft: '15px',
    textDecoration:'none',
  },
  login: {
 
        marginBottom: 10,
        borderRadius: '20px',
        backgroundColor:'black',
        color:'white',
      
  },
  logout: {
    boxShadow: 'none',
    backgroundColor:'#ff000000',
    color:'black',
    '&:hover': {
      backgroundColor: '#ff000000',
      color: '#928f8f',
      boxShadow: 'none',
  },
  
},
  [theme.breakpoints.down('sm')]:{
    mainContainer:{
      flexDirection: 'column-reverse',
    }
  },
  profile:{
    display:'flex',
    alignItems:'center',
    width: '250px',
    justifyContent: 'space-between',
  },
  adminName:{
    fontSize:'15px',
    color:'black',
    marginRight:'25px',
  }

  
}));