import React, { useState, useEffect } from 'react';
import { Button, Typography, Paper, Box, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  consentPanel: {
    width: 'auto',
    maxWidth: '600px',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '12px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    textAlign: 'left',
    fontSize: '24px', // equivalent to h6
    fontWeight: 'bold',
    letterSpacing: '0px',
    fontFamily: 'Open Sans',
    color: '#000000',
    opacity: 1,
    marginBottom: '20px',
    marginRight: 'auto',
  },
  bodyText: {
    fontFamily: 'Open Sans',
    fontSize: '13px', // Ensure this font is loaded in your project
    listStyle: 'none',
    margin: 0,
    paddingBottom: 35,

    color: '#3a3a3a'
  },
  buttonbox:{
    display:'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  button: {
    
    fontFamily: 'Open Sans',
    
    color: '#6e6e6e',
    width: '45%',
    height: '60px'
  },
  buttonText: {
    textTransform: 'none',
    fontFamily: 'Open Sans',
    margin: 0,
    padding: 0,
 
  },
  acceptButton: {
    color: '#fff',
    backgroundColor: '#000', // Black background
    '&:hover': {
      backgroundColor: theme.palette.grey[900], // Slightly lighter black on hover
    },
  },
  customizationPanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > div': {
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  switchLabel: {
    fontFamily: 'Open Sans',
    fontSize: '16px', // Adjust the font size as needed
    color: '#3a3a3a',
    marginRight: theme.spacing(2),
    flex: 1, // This ensures the label takes up the available space
  },
}));

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const getCookie = (name) => {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const CookieConsentPanel = () => {
  const classes = useStyles();
  const [showPanel, setShowPanel] = useState(false);
  const [showCustomization, setShowCustomization] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    personalization: false,
    analytics: false,
    advertising: false,
  });


  // ... setCookie and getCookie functions ...

  useEffect(() => {
    const cookieConsent = getCookie('cookieConsent');
    if (!cookieConsent) {
      setShowPanel(true);
    }
  }, []);

  const acceptCookies = () => {
    setCookie('cookieConsent', JSON.stringify({ ...cookiePreferences, necessary: true }), 365);
    setShowPanel(false);
  };

  const handleToggle = (preference) => {
    setCookiePreferences(prev => ({ ...prev, [preference]: !prev[preference] }));
  };

  const savePreferences = () => {
    setCookie('cookieConsent', JSON.stringify(cookiePreferences), 365);
    setShowPanel(false);
  };
  const declineCookies = () => {
    // ... Decline cookies logic ...
    setShowPanel(false);
  };
  const openCustomizationPanel = () => {
    setShowCustomization(true);
  };
  const COOKIE_PREFERENCES_TEXT = {
    necessary: {
      label: "Betrieb der Webseite",
      description: "Ich möchte mit einer sicheren Verbindung auf die Webseite zugreifen"
    },
    personalization: {
      label: "Personalisierte Webseite und Inhalte",
      description: "Ich möchte die Webseite tikamoon.de individuell erleben"
    },
    analytics: {
      label: "Statistik und Messung von Besucherdaten",
      description: "Ich möchte zur Verbesserung der Benutzerfreundlichkeit auf der Webseite tikamoon.de beitragen"
    },
    advertising: {
      label: "Personalisierte Werbung",
      description: "Ich möchte von Tikamoon Angebote haben, die zu meinen Vorlieben passen"
    },
  };
   const CustomizationPanel = () => (
    <div className={classes.customizationPanel}>
    {Object.entries(cookiePreferences).map(([key, value]) => (
      <div key={key}>
        <div>
          <Typography className={classes.switchLabel} variant="subtitle1">
            {COOKIE_PREFERENCES_TEXT[key].label}
          </Typography>
          <Typography className={classes.bodyText}>
            {COOKIE_PREFERENCES_TEXT[key].description}
          </Typography>
        </div>
        <Switch
          checked={value}
          onChange={() => handleToggle(key)}
          name={key}
          color="primary"
        />
      </div>
    ))}
    <Button onClick={savePreferences} className={classes.button}>
      Meine Auswahl bestätigen
    </Button>
  </div>
  );


  return (
    showPanel && (
      <div className={classes.overlay}>
        <Paper className={classes.consentPanel}>
          {!showCustomization ? (
            <>
             <Button
                className={classes.button}
                style={{ textDecoration: 'underline', marginLeft: 'auto' }}
                onClick={declineCookies}
              >
                <Typography className={classes.buttonText}>Weiter ohne Zustimmung</Typography>
              </Button>
              <Typography className={classes.title} gutterBottom>Ihre Cookie-Einstellungen</Typography>
              <Typography className={classes.bodyText} variant="body1" gutterBottom>
                Wir verwenden Cookies, um Ihnen optimales Surfen zu ermöglichen und passende
                Werbung zu schicken. Wir verwenden Cookies außerdem, um die Besucherzahlen der
                Webseite zu analysieren.
              </Typography>
              <Box className={classes.buttonbox} >
                <Button variant="outlined" className={classes.button} onClick={openCustomizationPanel}>
                  <Typography className={classes.buttonText}>Meine Auswahl anpassen</Typography>
                </Button>
                <Button variant="contained" className={`${classes.button} ${classes.acceptButton}`} onClick={acceptCookies}>
                  <Typography className={classes.buttonText} color="inherit">Alle akzeptieren</Typography>
                </Button>
              </Box>
            </>
          ) : (
            <CustomizationPanel />
          )}
        </Paper>
      </div>
    )
  );
};

export default CookieConsentPanel;