import React, { useEffect,useState, useMemo } from 'react';
import { Typography, Paper, CircularProgress, Divider, Button, Grid  } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';
import useStyles from './styles';
import { getPost } from '../../actions/posts';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PostCard from '../Posts/Post/Post.js'; // Replace with your actual PostCard component path
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

const PostDetails = () => {
  const { post, isLoading, posts } = useSelector((state) => ({
    post: state.posts.post,
    isLoading: state.posts.isLoading,
    posts: state.posts.posts // Assuming all posts are stored here
  }));

  const [languageLevel, setLanguageLevel] = useState('advanced'); // State to track selected language level


  const dispatch = useDispatch();
  const history = useHistory(); 

  // Function to handle back navigation
  const handleBack = () => {
    history.goBack();
  };

 
  const classes = useStyles({});
  const { id } = useParams();
 

  useEffect(() => {
    dispatch(getPost(id));
    // Optionally, fetch related posts here if your backend supports it
  }, [id, dispatch]);

  const loadingStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
   // Adjust height as needed
  };
  const relatedPosts = useMemo(() => {
    return posts ? posts.filter(p => 
      p._id !== post?._id && p.tags.some(tag => post?.tags?.includes(tag))
    ) : [];
  }, [posts, post]);
  
  const filterContentByLanguageLevel = (content, level) => {
    if (!content) return "";
    
    const regexPatterns = {
      beginner: /^(.*?)\*\*/,
      intermediate: /\*\*(.*?)\*\*\*/,
      advanced: /\*\*\*(.*)$/,
    };
    const regex = regexPatterns[level];
    const matches = content.match(regex);

    return matches ? matches[1] : "Content for this level is not available.";
  };

  const formatMessage = (message) => {
    if (!message) return "";
    const firstLetter = message.charAt(0);
    const restOfMessage = message.slice(1);
  
    return (
      
      <>
       <span style={{ fontSize: '110px', lineHeight: '87px', float: 'left', color: '#000000', fontWeight: '900', paddingRight: '8px'}}>
          {firstLetter}
        </span>
        {restOfMessage}
      </>
    );
  };

  if (!post) return null;
  if (isLoading) {
    return (
     <div style={loadingStyles}>
        <CircularProgress size="7em" style={{ color: '#1a866b' }} />
     </div>
    );
  }

  return (
    <>
    <Paper elevation={0} className={classes.paper} >
      <div className={classes.card}>
        <div className={classes.section}>
        <Button style={{backgroundColor: 'transparent' ,  borderRadius: '12px', border: 'solid'}} onClick={handleBack}> <ArrowBackIcon />Go Back  </Button>
         
          <Typography gutterBottom className={classes.tags}>
            {post.tags.map((tag) => `#${tag} `)}
          </Typography>
          <Typography variant="h3" component="h2" className={classes.title}>{post.title}</Typography>
          <Typography variant="h5" className={classes.titletext}>{post.titletext}</Typography>
          <Typography className={classes.cratedby}>Created by: {post.name}</Typography>
          <Typography variant="body1">{moment(post.createdAt).fromNow()}</Typography>
          
        </div>
        <Divider style={{ margin: '20px 0' }} />
        <div className={classes.imageSection}>
          <img className={classes.media} src={post.selectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} alt={post.title} />
          <Typography className={classes.fotoby}>Photo: {post.imagetext}</Typography>
        </div>
                  <Divider style={{ margin: '20px 0' }} />

       <FormControl variant="outlined" className={classes.formControl}>
  <InputLabel id="language-level-select-label">Sprachniveaus</InputLabel>
  <Select
    labelId="language-level-select-label"
    id="language-level-select"
    value={languageLevel}
    onChange={(e) => setLanguageLevel(e.target.value)}
    label="Language Level"
  >
    <MenuItem value="beginner">A1-A2</MenuItem>
    <MenuItem value="intermediate">B1-B2</MenuItem>
    <MenuItem value="advanced">C1-C2</MenuItem>
  </Select>
</FormControl>
        <div className={classes.messageContent}>
        <Typography className={classes.message} variant="body1" dangerouslySetInnerHTML={{ __html: filterContentByLanguageLevel(post.message, languageLevel) }} />
</div>
      </div>

    </Paper>
    <Divider style={{ margin: '20px 0' }} />
    {relatedPosts.length > 0 && (
      <div style={{ margin: '20px 0' }}>
        <Typography variant="h6">Das könnte Sie auch interessieren</Typography>
        <Divider style={{ margin: '20px 0' }} />
        <Grid container spacing={3}>
          {relatedPosts.map((relatedPost) => (
            <Grid item key={relatedPost._id} xs={12} sm={6} md={4}>
              <PostCard post={relatedPost} />
            </Grid>
          ))}
        </Grid>
      </div>
    )}
    </>
    
     
  );

};

export default PostDetails;
