import React from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import useStyles from './styles';

const LanguageLevelSelector = () => {
  const classes = useStyles();

  const levels = [
    { level: 'A1-A2 – Anfänge', description: 'Lernen Sie mit einfachen Sätzen und Wörtern. Ideal für Anfänger!' },
    { level: 'B1-B2 – Fortgeschrittene', description: 'Entwickeln Sie Ihre Kenntnisse weiter mit komplexeren Strukturen.' },
    { level: 'C1-C2 – Profis', description: 'Perfektionieren Sie Ihr Deutsch mit anspruchsvollen Texten.' }
  ];

  return (
    <Grid container spacing={2} className={classes.languageLevelContainer}>
      <Grid item xs={12}>
      <Typography  className={classes.levelsmall}>
          Stop panicking!
        </Typography>
        <Typography variant="h4" className={classes.leveltitle}>
          Wählen Sie Ihr Deutsch-Niveau!
        </Typography>
        <Typography variant="h4" className={classes.levelsubtitle}>
        Jetzt haben Sie die Möglichkeit, Artikel auf verschiedenen Niveaustufen zu lesen: 
        <strong> A</strong>, <strong>B </strong> oder <strong>C</strong>. <br />Dieses vielseitige Angebot ermöglicht es Ihnen, je nach Ihren aktuellen <br />Sprachkenntnissen und Interessen, den für Sie passenden Schwierigkeitsgrad auszuwählen.

        </Typography>
      </Grid>
      {levels.map((level) => (
        <Grid item xs={12} sm={4} key={level.level}>
          <Card className={classes.levelCard}>
            <CardContent>

              <Typography gutterBottom variant="h6" component="h2" className={classes.levelTitle}>
                {level.level}
              </Typography>
              <Typography variant="body2" component="p" className={classes.levelDescription}>
                {level.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default LanguageLevelSelector;
