import React from 'react';
import Form from './ContactForm';
import useStyles from './styles';
import { Paper, Button, Typography, CardActions } from '@material-ui/core';

const Contact = () => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
     <div className={classes.contactTextContainer}>
      <div className={classes.contactcontent} >
                <Typography className={classes.contacttitle} variant="h4">Kontaktieren Sie uns</Typography>
                <Typography className={classes.contacttext}>Gerne können Sie sich bei uns melden! Egal ob Sie Fragen zu unseren Dienstleistungen oder zu anderen Themen haben, unser Team steht bereit, um all Ihre Fragen zu beantworten. Bei Horizonthaus verpflichten wir uns, Ihnen den notwendigen Support zu bieten, wann immer Sie ihn benötigen.
            </Typography>
             
            </div>
       
   
      <Form />
    </div>
    </div>
  );
};

export default Contact;
