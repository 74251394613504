
import { FETCH_ALL, FETCH_POST, FETCH_BY_SEARCH, CREATE, UPDATE, DELETE ,START_LOADING, END_LOADING, FETCH_BY_TAG } from '../constants/actionTypes';
const initialState = {
  posts: [],
  post: null,
  isLoading: false,
  // ... any other fields you might want to track.
};

export default (state = initialState, action) => {
  
  switch (action.type) {
    case FETCH_ALL:
      return { ...state, posts: action.payload };
    case FETCH_BY_SEARCH:
      return { ...state, posts: action.payload };
    case FETCH_POST:
      return { ...state, post: action.payload };
      case FETCH_BY_TAG:  // Add this case
      return { ...state, posts: action.payload };
    case DELETE:
      return { ...state, posts: state.posts.filter((post) => post._id !== action.payload) };
    case UPDATE:
      return {...state, posts: state.posts.map((post) => post._id === action.payload._id ? action.payload : post)};
    case CREATE:
      return { ...state, posts: [...state.posts, action.payload] };
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    default:
      return state;
  }
  
};


