import React, {useState, useEffect} from 'react'
import { AppBar, Typography, Toolbar, Button, Avatar } from '@material-ui/core';
import { Link,useHistory, useLocation } from 'react-router-dom';
import useStyles from './styles';
import sitelogo from '../../images/logoo.png';
import {useDispatch} from 'react-redux';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
const Admin = () => {
    const classes = useStyles();

    const [admin, setAdmin] = useState(JSON.parse(localStorage.getItem('profile')));
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const logout = () => {
        dispatch({ type: 'LOGOUT'});
        history.push('/');
        setAdmin(null);
        window.location.reload();
    }
    
   

    useEffect(()=>{
       // const token = admin?.token;
        //JWT ...
        setAdmin(JSON.parse(localStorage.getItem('profile')))
        },[location]);
        

    return (
        <AppBar className={classes.appBar}  position="static" >
        <div className={classes.brandContainer}>

        <Link to="/">
            <img className={classes.image} src={sitelogo} alt="icon" height="auto" width="70px" />
        </Link>

        </div>
        <Toolbar className={classes.toolbar}>
            {admin ? (
                <div className={classes.profile}>
                    <Avatar className={classes.purple} alt={admin.result.name} src={admin.result.imageUrl}>{admin.result.name.charAt(0)}</Avatar>
                    <Typography className={classes.adminName} variant="h6">{admin.result.name}</Typography>
                    <Button variant="contained" className={classes.logout}  onClick={logout}>Logout<ExitToAppSharpIcon/></Button>
                </div>    
            ) : ( 
                <Button component={Link} to="/horizonthausadmin/auth/secret" variant="contained" className={classes.login} >Sign in</Button>

            )}

        </Toolbar>
      </AppBar>
    )
}

export default Admin
