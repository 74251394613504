// External library imports
import React, { useEffect, useState } from 'react';
import { Container, AppBar, Typography, Grow, Grid, Paper, TextField, Button,Divider } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ChipInput from 'material-ui-chip-input';

// Local imports
import { getPosts, getPostsBySearch, getPostsByTag } from '../../actions/posts';
import useStyles from './styles';
import Posts from '../Posts/Posts';
import Form from '../Form/Form';

// Custom hook to get query params
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Articels = () => {
    const classes = useStyles();
    const admin = JSON.parse(localStorage.getItem('profile'));
    const query = useQuery();
    const history = useHistory();
    const location = useLocation();

    // State
    const [currentId, setCurrentId] = useState(0);
    const [search, setSearch] = useState('');
    const [tags, setTags] = useState([]);

    const searchQuery = query.get('searchQuery');
    const dispatch = useDispatch();

    // Effects
    useEffect(() => {
        const path = location.pathname;
        if (path.includes('/tags/')) {
            const tagName = path.split('/tags/')[1];
            // Add logic to dispatch an action to fetch posts by this tag
            // For example:
            dispatch(getPostsByTag(tagName)); // Assuming you have such an action
        } else {
            dispatch(getPosts());
        }
    }, [location, dispatch]);
   
 

  
    // Handlers
    const searchPost = () => {
        console.log('Running searchPost function...');
        const startTime = performance.now();
        if (search.trim() || (tags && tags.length)) {
            dispatch(getPostsBySearch({ search, tags: tags.join(',') }));
            history.push(`/posts/search?searchQuery=${search || 'none'}&tags=${tags.join(',')}`);
        } else {
            history.push('/');
        }
        const endTime = performance.now();
        console.log(`searchPost function completed in ${endTime - startTime} milliseconds.`);
    };
    const handleKeyPress = (e) => { if (e.keyCode === 13) searchPost(); };
    const handleAdd = (tag) => setTags([...tags, tag]);
    const handleDelete = (tagToDelete) => setTags(tags.filter((tag) => tag !== tagToDelete));

    // Render
    return (
        <> 
            
            <Typography variant="h5">Alle Rubiken </Typography>

       <Divider style={{ margin: '20px 0' }} />
        
            <Grow in>
                <Container>
                    <Grid className={classes.mainContainer} container justifyContent="space-between" alignItems="stretch" spacing={3}>
                        <Grid item xs={12} sm={admin ? 7 : 12}><Posts setCurrentId={setCurrentId} /></Grid>
                        {admin && (
                            <Grid item xs={12} sm={4}><Form currentId={currentId} setCurrentId={setCurrentId} /></Grid>
                        )}
                    </Grid>
                   
                </Container>
            </Grow>
        </>
    );
};

export default Articels;