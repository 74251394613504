import { makeStyles } from '@material-ui/core/styles';
import useStyles from './styles';

export default makeStyles((theme) => ({
  appBar: {
    borderRadius: 15,
    margin: '30px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    boxShadow: '0 0 0',
    backgroundColor: '#f8f7f4',
   
  },

  [theme.breakpoints.down('sm')]:{
    mainContainer:{
      flexDirection: 'column-reverse',
    }
  },
  [theme.breakpoints.down('xs')]:{
    mainContainer:{
      flexDirection: 'column-reverse',
    }
  },
  
  homeHeader: {
    flex:'1',
    justifyContent: 'center',
 
    paddingRight: '45px',
    display: 'flex',
   flexDirection:'column',
   [theme.breakpoints.down('sm')]: {
    paddingRight: '0px',
  },
   
  },

  
  
  headtitle: {
   
    fontFamily: 'Open Sans',
  
    fontSize: '50px',

    
    fontWeight: '600',
    letterSpacing: '5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
      letterSpacing: '3px',
    },
    

  }
  ,
  
  headtext: {
    color:'#4f4f4f',
    fontFamily: 'Open Sans , sans-seri8f',
    fontWeight:'400',
    fontSize:'14px',
    
    textAlign: 'justify',
    lineHeight: 1.8,
    letterSpacing: '0.01071em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },

 
  category: {
    flex: 1,
    display: 'flex',
    alignItems:'center',
    gap: '20px',
    justifyContent:'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop:'20px'
    },
    
    // other styles...
  },

  carouselContainer: {
    marginBottom: '30px',
    borderRadius: '20px',
    width: '100%',
    height: '70vh', // Default height
    [theme.breakpoints.down('sm')]: {
      height: '40vh', // Height for mobile screens
    },
  },
  postItemContainer: {
    position: 'relative', 
    overflow: 'hidden', 
    width: '100%', 
    height: '70vh', 
    marginBottom: '20px', 
    borderRadius: '20px' ,
   
    [theme.breakpoints.down('sm')]: {
      height: '40vh',
      marginBottom: '20px', 
    borderRadius: '20px' , 
    position: 'relative', 
    overflow: 'hidden', // Height for mobile screens
    },
  },

navButton: {
  backgroundColor: 'white',
  borderRadius: '50%', 
  '& > *': { // This targets the child elements of the navButton, which are the arrows
      color: 'black'
  }
},
indicatorContainer: {
  position: 'absolute',
  bottom: '5%',
  left: '50%',
  transform: 'translateX(-50%)',
  color:'red',
  zIndex:'1000'
},
postItemContainer: {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  
},
postImage: {
  width: '100%',
  height: '70vh',
  objectFit: 'cover'
},
postInfo: {
  position: 'absolute',
  top: '50%',
  left: '10%',
  color: 'white',
  width: '300px',
  
},
checkButton: {
  marginTop: '10px',

},

carouselTextWrapper: {
  backgroundColor: 'red',
  borderRadius: 8,
  padding: '10px',
  boxShadow: '0px 4px 6px #00000055',
  display: 'inline-block',
  position: 'absolute',
  top: '40%',
  left: '10%',
  color: 'white',
  width: '40%',
},
carouseltitle:{
  color:'white',
  boxShadow: 'none',
  fontFamily: 'Open Sans , sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize:'14px', // Height for mobile screens
  }, 
},
carouseltitletext:{
  color:'white',
  boxShadow: 'none',
     fontFamily: 'Open Sans , sans-serif',
    fontWeight:'400',
    fontSize:'14px',
    marginBottom:'5px',
    letterSpacing: '0.03em',
    paddingTop:'10px',
    [theme.breakpoints.down('sm')]: {
      display:'none' // Height for mobile screens
    }, 
},
cardActions: {
  padding: '8px 0px',
  display: 'flex',
  justifyContent: 'space-between',
},
trennen:{
height:'50px',
borderBottom: '1px solid #bcbcbc',
width: '100%',
margin:'10px 0px 60px 0px'
},
darkGradient: {
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to right, rgb(0 0 0 / 99%), transparent)',
    pointerEvents: 'none', // Ensure overlay doesn't interfere with interactions
  }},
 

button: {
    margin: theme.spacing(1),
    // Additional button styles...
},
imageDisplay: {
    flex: 1,
    minHeight: '300px',
    
    backgroundPosition: 'center',
    backgroundColor: '#e7e7e7',
    borderRadius:'20px', // Default color
    backgroundSize: 'cover',
    overflow: 'hidden', 
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
},
homeMain: {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  marginBottom: '5%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
},


categoryList:{

    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: '#f1efe9',
    width: '100%',
    borderRadius:'20px',
    flex: 1,

        alignItems: 'center', // Vertically aligns children in the middle
        justifyContent: 'flex-start',
},
List:{
  padding:'10px 20px',
  
  display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
},
Listbutton: {
  color: '#949191 !important',
  fontFamily: 'Open Sans , sans-serif !important',
  fontWeight:'500 !important',
  fontSize:'16px !important',
  backgroundColor: 'transparent !important', // Remove background
  boxShadow: 'none !important', 
  '&:hover': {
    color: '#1a866b !important',
    textDecoration: 'underline !important',
    backgroundColor: 'transparent !important', // Remove background
  boxShadow: 'none !important', 
  [theme.breakpoints.down('sm')]: {
    fontSize: '9px !important', // font size for screens smaller than 600px (typically mobile)
  },

},},
appBarSearch: {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),  // for spacing if required
  // ... any other styles you might have for appBarSearch ...
},
appBarMain:{
margin:'30px'
},
pagination: {
  borderRadius: 4,
  marginTop: '1rem',
  padding: '16px',
  backgroundColor:'#f8f7f4 !important',
},
paper: {
  padding: theme.spacing(2),
  boxShadow: 'none',
  borderRadius: '12px',
  
},




cardText: {
  position: 'absolute', 
  top: '40%', 
  left: '10%', 
  color: 'white', 
  width: '40%',
  [theme.breakpoints.down('sm')]: {
    top: '20%', 
    left: '20%', 
    width: '50%' ,
  }

}
,

  parttitle:{
    fontSize: '1.5rem',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    lineHeight: '1.334',
   
    marginBottom:'30px'

  },

  // Stile für die LanguageLevelSelector-Komponente
  leveltitle: {
    fontSize: '2.5rem',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    lineHeight: '1.334',
    marginBottom: '15px',
    textAlign: 'center', // Zentriert den Text horizontal
    width: '100%', // Stellt sicher, dass die Breite 100% des Containers ist
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    }
  },
  levelsubtitle:{
    fontSize: '1rem',
    fontFamily: 'Open Sans',
    fontWeight: '400',
    lineHeight: '22px',
    marginBottom: '30px',
    textAlign: 'center', // Zentriert den Text horizontal
    width: '100%',
  },

  levelCard: {
    textAlign: 'center',
    backgroundColor: 'rgb(241 239 233)',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '20px', // Fügt den border-radius hinzu
    boxShadow: theme.shadows[0], // Optionaler Schatten für mehr Tiefe
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'white', // Ändert die Hintergrundfarbe beim Hovern
    }, // Fügt einen kleinen Abstand zwischen den Karten hinzu
  },

  levelTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },

  levelDescription: {
    fontSize: '16px',
    color: '#5d5c5c',
    overflow: 'hidden',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '200',
    textOverflow: 'ellipsis',
   
  },

  languageLevelContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex', // Flex Container
    flexDirection: 'row', // Ordnet Kinder horizontal an
    justifyContent: 'center', // Zentriert Kinder im Container
    flexWrap: 'wrap', // Erlaubt Umbruch auf kleinere Bildschirme
  },
  levelsmall:{
    fontFamily: 'Indie Flower, cursive',
    fontWeight: '400',
    fontStyle: 'normal',
    textAlign: 'center',
    fontSize: '2rem',
   
    color: '#1c866d',
    letterSpacing: '2px'

    

  }
  

}));