import React from 'react';
import { Grid, Paper, Typography, Card, CardContent } from '@material-ui/core';
import useStyles from './styles';

const Legal = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom className={classes.title}>
          Impressum
          </Typography>
        
        </Grid>

        {/* Jede Card sollte in ihrer eigenen Grid item Komponente sein */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Webseitenbetreiber:
              </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Hilmi Mustafa Naz <br></br>
              Dempsher Gasse 4/<br></br>
              1180 Wien<br></br>
              Österreich
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Kontakt:
              </Typography>
              <Typography variant="body1" className={classes.titletext}>
              E-Mail: contact@horizonthaus.com


              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Haftung für Inhalte:
              </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 24 Mediengesetz für eigene Inhalte auf diesen Seiten verantwortlich.
              </Typography>
            </CardContent>
          </Card>
        </Grid>


         <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Haftung für Links:
                            </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Urheberrecht:
                            </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem österreichischen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Datenschutz:
                            </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Als Betreiber dieser Seite nehmen wir den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Füge weitere Karten hinzu, wenn nötig */}
      </Grid>




        <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom className={classes.title}>
          Datenschutzerklärung
          </Typography>
        
        </Grid>

        {/* Jede Card sollte in ihrer eigenen Grid item Komponente sein */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Datenerfassung und -verwendung für den Newsletter:
              </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Wir bieten auf unserer Website die Möglichkeit an, einen Newsletter zu abonnieren. Wenn Sie sich für unseren Newsletter anmelden, verwenden wir die von Ihnen angegebenen Daten ausschließlich für den Versand des Newsletters. Die von Ihnen angegebenen Daten bleiben bei uns gespeichert, bis Sie sich vom Newsletter abmelden und werden nach Abbestellung des Newsletters gelöscht.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Personalisierung des Newsletters:
              </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Um Ihnen einen personalisierten Newsletter bieten zu können, analysieren wir, wie Sie unsere Website und den Newsletter nutzen. Diese Analyse hilft uns, Ihnen Inhalte anzubieten, die Ihren Interessen entsprechen.


              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Widerrufsrecht:

              </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Sie können Ihre Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des Newsletters jederzeit widerrufen. Der Widerruf kann über einen Link in den Newslettern selbst oder per Mitteilung an die oben stehenden Kontaktmöglichkeiten erfolgen.              </Typography>
            </CardContent>
          </Card>
        </Grid>


         <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Haftung für Links:
                            </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Urheberrecht:
                            </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem österreichischen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Datenschutz:
                            </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Als Betreiber dieser Seite nehmen wir den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Füge weitere Karten hinzu, wenn nötig */}
      </Grid>



     <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom className={classes.title}>
          Verwendung von Cookies zur Personalisierung
                    </Typography>
        
        </Grid>

        {/* Jede Card sollte in ihrer eigenen Grid item Komponente sein */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Arten von Cookies:
                            </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Unsere Website verwendet Cookies, um das Nutzererlebnis zu verbessern und die Website besser auf die Bedürfnisse unserer Besucher abzustimmen. Wir verwenden sowohl Session-Cookies, die nach dem Schließen des Browsers gelöscht werden, als auch dauerhafte Cookies, die für einen längeren Zeitraum gespeichert bleiben.              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Zweck der Cookies:
                            </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Die durch Cookies gesammelten Informationen werden verwendet, um die Inhalte unserer Website zu personalisieren, Ihnen gezielte Werbung anzuzeigen und unsere Dienste zu verbessern.

              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Verwaltung von Cookie-Einstellungen:

              </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Sie haben die Möglichkeit, Ihre Cookie-Einstellungen zu verwalten. Sie können Cookies in Ihrem Browser deaktivieren oder einschränken, was jedoch die Funktionalität der Website beeinträchtigen kann. Informationen zur Verwaltung Ihrer Cookie-Einstellungen finden Sie in den Einstellungen Ihres Browsers.
              </Typography>    </CardContent>
          </Card>
        </Grid>


         <Grid item xs={12} sm={6} md={4}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
              Einwilligung und Widerruf:
                            </Typography>
              <Typography variant="body1" className={classes.titletext}>
              Mit der Nutzung unserer Website stimmen Sie der Verwendung von Cookies zu. Sie können Ihre Einwilligung zur Verwendung von Cookies jederzeit widerrufen, indem Sie Ihre Browsereinstellungen ändern und vorhandene Cookies löschen. </Typography>
            </CardContent>
          </Card>
        </Grid>
      
       

        {/* Füge weitere Karten hinzu, wenn nötig */}
      </Grid>





    </Paper>
  );
};

export default Legal;


 