import React, { useMemo } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CustomSpinner from '../CustomSpinner';

import Post from '../Posts/Post/Post';
import useStyles from './styles';

const Persons = ({ setCurrentId }) => {
  let admin = null;

  try {
    admin = JSON.parse(localStorage.getItem('profile'));
  } catch (error) {
    console.error("Error parsing the profile from local storage:", error);
  }

  const posts = useSelector((state) => state.posts.posts);

  const filteredAndSortedPosts = useMemo(() => {
    return posts && Array.isArray(posts)
      ? posts
          .filter(post => post.tags && post.tags.includes('PERSON')) // Filter posts with 'person' tag
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 3)
      : [];
  }, [posts]);

  const classes = useStyles();

  const loadingStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    !filteredAndSortedPosts.length ? (
      <div style={loadingStyles}>
        <CustomSpinner size="7em" style={{ color: '#1a866b' }} />
      </div>
    ) : (
      <Grid className={classes.container} container alignItems="stretch" spacing={3}>
        {filteredAndSortedPosts.map((post, index) => (
          <Grid key={post._id} item xs={12} sm={6} md={admin ? 6 : 4}>
            <Post post={post} setCurrentId={setCurrentId} index={index} />
          </Grid>
        ))}
      </Grid>
    )
  );
};

export default Persons;
