import React, { useState } from 'react';
import useStyles from './styles.js'; // Adjust the import path as necessary
import { TextField, Button, Typography, Container, Paper } from '@material-ui/core';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
  
      if (response.ok) {
        alert('Email sent successfully!');
        // Optionally reset the form here
        setFormData({ firstName: '', lastName: '', email: '', message: '' });
      } else {
        alert('Failed to send email. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while sending the email.');
    }
  };



  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} elevation={3}>
        <Typography variant="h5">Get in Touch</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
           className={classes.inputField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="Vorname"
            name="firstName"
            autoComplete="fname"
            autoFocus
            value={formData.firstName}
            onChange={handleChange}
          />
          <TextField
           className={classes.inputField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Nachname"
            name="lastName"
            autoComplete="lname"
            value={formData.lastName}
            onChange={handleChange}
          />
          <TextField
           className={classes.inputField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Addresse"
            name="email"
            autoComplete="email"
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
           className={classes.inputField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="message"
            label="Ihre Nachricht"
            name="message"
            autoComplete="message"
            multiline
            rows={4}
            value={formData.message}
            onChange={handleChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
           Absenden
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default Contact;
