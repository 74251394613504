import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import film from '../../images/film.jpg';
import gesellschaft from '../../images/gesellschaft.jpg';
import kultur from '../../images/kultur.jpg';
import story from '../../images/story.jpg';
import science from '../../images/science.jpg';
import reise from '../../images/reisen.jpg';
import backie from '../../images/backie.png';
import person from '../../images/person.jpg';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const HomeHeader = () => {
    const classes = useStyles();
    const [image, setImage] = useState(backie);

     const categories = [
        { name: 'Wissenschaft', path: '/science', img: science },
        { name: 'Kultur & Kunst', path: '/culture', img: kultur },
        { name: 'Gesellschaft', path: '/society', img: gesellschaft },
        { name: 'Geschichte', path: '/history', img: story },
        { name: 'Filme & Serien', path: '/movies', img: film },
        { name: 'Essen & Reisen', path: '/foodtravel', img: reise },
        { name: 'Person', path: '/person', img: person },
    ];
    // Function to format category name for the URL
    const formatPathName = (name) => {
        return name.toLowerCase().replace(/\s&\s/g, '-').replace(/\s/g, '');
    };

    const renderedCategories = useMemo(() => {
        return categories.map((cat, index) => (
            <Button
                key={index}
                component={Link}
                to={`/tags/${formatPathName(cat.name)}`}
                className={classes.Listbutton}
                onMouseEnter={() => setImage(cat.img)}
                onMouseLeave={() => setImage(backie)}
            >
                #{cat.name.toUpperCase()}
            </Button>
        ));
    }, [categories, setImage]);

     return (
        <div className={classes.homeMain}>
            <div className={classes.homeHeader}>
                <h1 className={classes.headtitle}>HorizontHaus Magazine</h1>
                <h2 className={classes.headtext}>
                    Entdecken Sie eine Welt voller Faszination, Inspiration und Wissen. Wir sind stolz darauf, Ihnen jeden Monat frische, sorgfältig recherchierte Artikel zu präsentieren, die Ihr Wissen erweitern und Ihre Neugierde wecken.
                </h2>
                <Button
                 component={Link}
                 to={`/articels`}
                 style={{backgroundColor: 'transparent' ,  marginTop: '10px',color: 'black',width: '150px',  borderRadius: '12px', backgroundColor: '#ebfe38',height: '50px'}}
                 > Alle Rubiken<ArrowForwardIcon/></Button>

            </div>
            <div className={classes.category}>
                <div className={classes.categoryList}>
                    <div className={classes.List}>
                    {renderedCategories}
                    </div>
                </div>
                <div
                    className={classes.imageDisplay}
                    style={{ backgroundImage: `url(${image})` }}
                />
            </div>
        </div>
    );
};

export default HomeHeader;
