import React, { useMemo } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CustomSpinner from '../CustomSpinner'; // import the new spinner component

import Post from './Post/Post';
import useStyles from './styles';

const Posts = ({ setCurrentId }) => {
  let admin = null;

  try {
    admin = JSON.parse(localStorage.getItem('profile'));
  } catch (error) {
    console.error("Error parsing the profile from local storage:", error);
  }
  const posts = useSelector((state) => state.posts.posts);

  const sortedPosts = useMemo(() => {
    return posts && Array.isArray(posts) ? 
      [...posts].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
  }, [posts]);

  const classes = useStyles();

  const loadingStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // Adjust height as needed
  };

  return (
    !sortedPosts.length ? (
      <div style={loadingStyles}>
        <CustomSpinner size="7em" style={{ color: '#1a866b' }} />
      </div>
    ) : (
      <Grid className={classes.container} container alignItems="stretch" spacing={3}>
        {sortedPosts.map((post, index) => (
          <Grid key={post._id} item xs={12} sm={6} md={admin ? 6 : 4}>
            <Post post={post} setCurrentId={setCurrentId} index={index} />
          </Grid>
        ))}
      </Grid>
    )
  );
};

export default Posts;
