import axios from 'axios';

//const API = axios.create({ baseURL:'https://horizonthaus-app.onrender.com/' });

const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')){
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
    return req;
});
export const fetchPost = (id) => {  // Added the id parameter here
    console.log('Fetching posts...');
    const startTime = performance.now();
    return API.get(`/posts/${id}`)
        .then(response => {
            const endTime = performance.now();
            console.log(`Fetched posts in ${endTime - startTime} milliseconds.`);
            return response;
        })
        .catch(error => {
            console.error('Error fetching posts:', error);
            throw error;
        });
};


export const fetchPosts = () => {
    console.log('Fetching posts...');
    const startTime = performance.now();
    return API.get('/posts')
        .then(response => {
            const endTime = performance.now();
            console.log(`Fetched posts in ${endTime - startTime} milliseconds.`);
            return response;
        })
        .catch(error => {
            console.error('Error fetching posts:', error);
            throw error;
        });
};

export const fetchPostsBySearch = (searchQuery) => {
    const query = encodeURIComponent(searchQuery.search || 'none');
    const tags = encodeURIComponent(searchQuery.tags);
    console.log(`Fetching posts by search with query: ${query} and tags: ${tags}...`);
    const startTime = performance.now();
    return API.get(`/posts/search?searchQuery=${query}&tags=${tags}`)
        .then(response => {
            const endTime = performance.now();
            console.log(`Fetched posts by search in ${endTime - startTime} milliseconds.`);
            return response;
        })
        .catch(error => {
            console.error('Error fetching posts by search:', error);
            throw error;
        });
};

export const createPost = (newPost) => {
    console.log('Creating post...');
    return API.post('/posts', newPost)
        .then(response => {
            console.log('Post created successfully.');
            return response;
        })
        .catch(error => {
            console.error('Error creating post:', error);
            throw error;
        });
};

export const updatePost = (id, updatedPost) => {
    console.log(`Updating post with ID: ${id}...`);
    return API.patch(`/posts/${id}`, updatedPost)
        .then(response => {
            console.log(`Post with ID: ${id} updated successfully.`);
            return response;
        })
        .catch(error => {
            console.error(`Error updating post with ID: ${id}:`, error);
            throw error;
        });
};

export const deletePost = (id) => {
    console.log(`Deleting post with ID: ${id}...`);
    return API.delete(`/posts/${id}`)
        .then(response => {
            console.log(`Post with ID: ${id} deleted successfully.`);
            return response;
        })
        .catch(error => {
            console.error(`Error deleting post with ID: ${id}:`, error);
            throw error;
        });
};

export const signIn = (formData) => {
    console.log('Signing in...');
    return API.post('/admin/signin', formData)
        .then(response => {
            console.log('Signed in successfully.');
            return response;
        })
        .catch(error => {
            console.error('Error signing in:', error);
            throw error;
        });
};

export const signUp = (formData) => {
    console.log('Signing up...');
    return API.post('/admin/signup', formData)
        .then(response => {
            console.log('Signed up successfully.');
            return response;
        })
        .catch(error => {
            console.error('Error signing up:', error);
            throw error;
        });
};

export const subscribeToNewsletter = (subscriberData) => {
    console.log('Subscribing to newsletter...');
    return API.post('/api/subscribe', subscriberData)
        .then(response => {
            console.log('Subscription successful.');
            return response;
        })
        .catch(error => {
            console.error('Error subscribing to newsletter:', error);
            throw error;
        });
};

export const fetchPostsByTag = (tagName) => {
    console.log(`Fetching posts with tag: ${tagName}...`);
    const startTime = performance.now();
    return API.get(`/posts/tags/${tagName}`) // Assuming you have an endpoint like this
        .then(response => {
            const endTime = performance.now();
            console.log(`Fetched posts with tag ${tagName} in ${endTime - startTime} milliseconds.`);
            return response;
        })
        .catch(error => {
            console.error(`Error fetching posts with tag ${tagName}:`, error);
            throw error;
        });
};

