// External library imports
import React, { useState } from 'react';
import { Grid, Paper, Typography, TextField, Button, MenuItem, Select,  IconButton } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import PinterestIcon from '@material-ui/icons/Pinterest';
import { Link } from 'react-router-dom';


// Local imports
import useStyles from './styles';
import logo from '../../images/logoo.png'; // Replace with your actual logo import path

const Footer = ({ openCookieSettings }) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [language, setLanguage] = useState('en');
  const navLinks = [
    { title: 'Home', path: '/' },
    { title: 'Rubiken', path: '/articels' },
    { title: 'About Us', path: '/about-us' },
    { title: 'Contact', path: '/contact' },
    // ... add more links as needed
  ];
  const handleSubscribe = () => {
    // Implement your subscribe logic here
    console.log('Subscribed with email:', email);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };
  const spotifyIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 168 168">
      <path fill="black" d="M84,0C37.7,0,0,37.7,0,84s37.7,84,84,84s84-37.7,84-84S130.3,0,84,0z M121.9,125.9
        c-1.6,2.6-4.8,3.4-7.4,1.8c-19.4-11.8-43.9-14.4-72.8-7.7c-2.8,0.7-5.7-0.9-6.4-3.7c-0.7-2.8,0.9-5.7,3.7-6.4
        c31.4-7.3,58.5-4.2,80.4,9.1C122.6,119.9,123.5,123.2,121.9,125.9z M133.2,100.2c-2,3.3-6.1,4.3-9.4,2.3
        c-22.6-14.1-58.2-17.2-91.1-9.1c-3.6,0.9-7.3-1.2-8.2-4.8c-0.9-3.6,1.2-7.3,4.8-8.2c36.4-9,76.2-5.2,102.3,10.7
        C133.9,92.5,135.2,96.9,133.2,100.2z M137.7,74.5c-26.7-16.7-69.3-21-102.4-11.8c-4.3,1.1-8.8-1.6-9.9-5.9
        c-1.1-4.3,1.6-8.8,5.9-9.9c36.9-10.2,84.1-5.2,115.3,13.7c2.7,1.6,3.7,5.1,2.1,7.8C145.1,73.7,140.4,76.1,137.7,74.5z"/>
    </svg>
  );
  

  return (
  
    <footer className={classes.footer}>
   
    <Grid container alignItems="center" justifyContent="space-between" className={classes.topContainer}>
      <Grid  className={classes.logoContainer}>
        <img src={logo} alt="Logo" className={classes.logo} />
        {navLinks.map((link, index) => (
  <Link key={index} to={link.path} className={classes.navLink}>
    {link.title}
  </Link>
))}
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={classes.navLinksContainer}>
      <Typography variant="body2" style={{ padding: '10px',color: 'black', borderRadius: '12px', backgroundColor: '#ebfe38'}} >
      Social Media
        </Typography>
      <IconButton href="https://www.instagram.com/horizonthaus/" target="_blank" className={classes.iconButton}>
          <InstagramIcon />
        </IconButton>
        <IconButton href="https://www.pinterest.at/horizonthaus/" target="_blank" className={classes.iconButton}>
          <PinterestIcon />
        </IconButton>
         <IconButton component="a" href="https://open.spotify.com/user/31rn6gjvjj76jgbxlqcn26lb2ob4" target="_blank" rel="noopener noreferrer">
        {spotifyIcon}
      </IconButton>
        
      </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between">
    
      <Grid>
        <Typography variant="body2" className={classes.legalText}>
          &copy; {new Date().getFullYear()} Horizonthaus
        </Typography>
     
   

      </Grid>
      <Grid item xs={12} sm={12} md={4} className={classes.socialAndLegalContainer}>
    
      <Link to="/newsletter" className={classes.legalLink}>Newsletter</Link>
        <Link to="/legal" className={classes.legalLink}>Legal</Link>

      </Grid>
      </Grid>
   
  </footer>
  
  );
};

export default Footer;
