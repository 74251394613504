import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios'; // make sure you are using this if necessary
import useStyles from './styles'; // Make sure the path to your styles.js is correct
import { TextField, Button, Typography, Container, Paper, Grid } from '@material-ui/core';
import { subscribeToNewsletter } from '../../api'; // Adjust the import path as necessary
import error from '../../images/404.png';
import confetti from 'canvas-confetti';

const NotFoundPage = () => {
   
    const classes = useStyles();

   

   
    return (
        <Container component="main" maxWidth="md">
            <Grid container spacing={3} className={classes.flexContainer}>
                <Grid item xs={12} md={6} className={classes.imageSide}>
                    {/* Place your image here */}
                    <img src={error} alt="errorImage" className={classes.errorImage} />
                </Grid>
                <Grid item xs={12} md={6} className={classes.formSide}>
                    <Paper className={classes.paper} >
                        <Typography className={classes.errortitle}> 404</Typography>
                        <Typography className={classes.errortext}> Die Seite, die Sie suchen, konnte nicht gefunden werden.</Typography>
                       

      <Link to="/" className={classes.homeBack}>Zurück zur Startseite</Link>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NotFoundPage;
