import React, { useState } from 'react';
import axios from 'axios'; // make sure you are using this if necessary
import useStyles from './styles'; // Make sure the path to your styles.js is correct
import { TextField, Button, Typography, Container, Paper, Grid } from '@material-ui/core';
import { subscribeToNewsletter } from '../../api'; // Adjust the import path as necessary
import newsletter from '../../images/news.jpg';
import confetti from 'canvas-confetti';

const NewsletterMain = () => {
    const [subscriber, setSubscriber] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });
    const classes = useStyles();
    const [isUnsubscribing, setIsUnsubscribing] = useState(false);


    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const triggerConfetti = () => {
        confetti({
            angle: 60,
            spread: 55,
            particleCount: 100,
            origin: { x: 0 }
        });
        confetti({
            angle: 120,
            spread: 55,
            particleCount: 100,
            origin: { x: 1 }
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        try {
            const response = await subscribeToNewsletter(subscriber);
            if (response.data.message) {
                setSuccessMessage(response.data.message);
                setSubscriber({ firstName: '', lastName: '', email: '' }); // Reset form
                triggerConfetti(); // Trigger confetti effectform
            }
        } catch (error) {
            console.error(error.response.data.message);
            setErrorMessage('Anmeldung fehlgeschlagen.');
        }
    };
    const handleUnsubscribe = async (event) => {
        event.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');
    
        try {
            // Assuming the API endpoint for unsubscribing is '/api/unsubscribe'
            const response = await axios.post('/api/unsubscribe', { email: subscriber.email });
    
            // Check if the backend sends a success message and update the state accordingly
            if (response.data.message) {
                setSuccessMessage(response.data.message);
                // Optionally reset the subscriber's email if unsubscription is successful
                setSubscriber({ ...subscriber, email: '' });
            } else {
                // Handle the case if no message is sent from the backend
                setErrorMessage('Abmeldung fehlgeschlagen.');
            }
        } catch (error) {
            // Handle any errors that occur during the API call
            console.error(error.response?.data?.message || error.message);
            setErrorMessage('Abmeldung fehlgeschlagen.');
        }
    };
    


    const toggleMode = () => setIsUnsubscribing(!isUnsubscribing);


    const handleChange = (e) => {
        setSubscriber({ ...subscriber, [e.target.name]: e.target.value });
    };

    

    return (
        <Container component="main" maxWidth="md">
            <Grid container spacing={3} className={classes.flexContainer}>
                <Grid item xs={12} md={6} className={classes.imageSide}>
                    <img src={newsletter} alt="Newsletter" className={classes.newsletterImage} />
                </Grid>
                <Grid item xs={12} md={6} className={classes.formSide}>
                    <Paper className={classes.paper} >
                        <Typography className={classes.subtitle}> Lass dich inspirieren</Typography>
                        <Typography className={classes.subtext}> Abonnieren Sie unseren Newsletter und gehören Sie zu den Ersten, die von Neuheiten, Aktionen und Sonderangeboten erfahren.</Typography>
                        {successMessage && <div className={classes.successmessage}>{successMessage}</div>}
                        {errorMessage && <div className={classes.errormessage}>{errorMessage}</div>}
    
                        {isUnsubscribing ? (
                            <form className={classes.form} onSubmit={handleUnsubscribe}>
                                <TextField
                                    className={classes.inputField}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Addresse"
                                    name="email"
                                    autoComplete="email"
                                    value={subscriber.email}
                                    onChange={handleChange}
                                />
                                <Button
                                    className={classes.submit}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                >
                                    Abmelden
                                </Button>
                            </form>
                        ) : (
                            <form className={classes.form} onSubmit={handleSubmit}>
                                <TextField
                                    className={classes.inputField}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="Vorname"
                                    name="firstName"
                                    autoComplete="fname"
                                    value={subscriber.firstName}
                                    onChange={handleChange}
                                />
                                <TextField
                                    className={classes.inputField}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Nachname"
                                    name="lastName"
                                    autoComplete="lname"
                                    value={subscriber.lastName}
                                    onChange={handleChange}
                                />
                                <TextField
                                    className={classes.inputField}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Addresse"
                                    name="email"
                                    autoComplete="email"
                                    value={subscriber.email}
                                    onChange={handleChange}
                                />
                                <Button
                                    className={classes.submit}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                >
                                    Anmelden
                                </Button>
                            </form>
                        )}
    
                        <Button onClick={toggleMode} className={classes.toggleButton}>
                            {isUnsubscribing ? "Wechseln zu Anmelden" : "Wechseln zu Abmelden"}
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
      );
    };
    
    // Make sure this line is outside and after your component declaration
    export default NewsletterMain;