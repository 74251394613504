import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  media: (props) => ({
    height: 0,
    borderRadius: '20px', // Rounded corners
   // backgroundColor: 'rgba(0, 0, 0, 0.5)',
   // backgroundBlendMode: 'darken',
    backgroundSize: 'cover', // This will ensure the image covers the entire div
    backgroundPosition: 'center center', // Center the image if it doesn't fit perfectly
  }),
  alternatePost: {
    "& .MuiCardMedia-root": {
        paddingTop: '75%', // This adjusts it to the 1.33322:1 ratio
    },
},
evenPostMedia: {
  paddingTop: '100% !important',
},
oddPostMedia: {
  paddingTop: '75% !important',
},


  border: {
    border: 'solid',
  },
  fullHeightCard: {
    height: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: 'none',

    position: 'relative',
    backgroundColor: '#ff000000',


  },
 
  overlay: {
    position: 'absolute',
    top: '25px',
    left: '20px',
    background: '#c1c1c178',
    borderRadius:' 4.2rem',
    height: '3.2rem',
    
  },
  overlay2: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    color: 'white',
  },
  grid: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '5px',
  },
  date:{
    fontFamily: 'Open Sans , sans-serif',
    fontWeight:'300',
    fontSize:'10px',
    color:'#1a866b'
  },
  title: {
   
    fontFamily: 'Open Sans , sans-serif',
    fontWeight:'400',
    height: '100%', // Fixed height
    overflow: 'hidden',
    textOverflow: 'ellipsis',
   
    WebkitLineClamp: 2, // Display up to 2 lines (camelCased)
    WebkitBoxOrient: 'vertical',
  },
  cardActions: {
    padding: '0 0px 8px 0px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  GESELLSCHAFT:{
   
    fontFamily: 'Open Sans , sans-serif',
    fontWeight:'500',
    fontSize:'13px',
     
  },
  ESSEN:{
    
    fontFamily: 'Open Sans , sans-serif',
    fontWeight:'500',
    fontSize:'13px',
  },
  FILME:{
    
    fontFamily: 'Open Sans , sans-serif',
    fontWeight:'500',
    fontSize:'13px',
  },
  WISSENSCHAFT:{
    
    fontFamily: 'Open Sans , sans-serif',
    fontWeight:'500',
    fontSize:'13px',
  },
  GESCHICHTE:{
    
    fontFamily: 'Open Sans , sans-serif',
    fontWeight:'500',
    fontSize:'13px',
  },
  KULTUR:{
    
    fontFamily: 'Open Sans , sans-serif',
    fontWeight:'500',
    fontSize:'13px',
  },
  
  
  titletext:{
    fontFamily: 'Open Sans , sans-serif',
    fontWeight:'200',
    color: '#8b8b8b',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  
    WebkitLineClamp: 4, // Display up to 4 lines (camelCased)
    WebkitBoxOrient: 'vertical',
  },
  tag:{
    fontFamily: 'Raleway, sans-serif',
    fontWeight:'500',
    fontSize:'13px',
    color:'#ebfe36',
    letterSpacing:' 0.01px',
    alignItems: 'center',
   
    backdropFilter: 'blur(15px)',
    boxShadow: 'rgb(0 0 0 / 25%) 0px 0px 1px',
  
    display: 'flex',
 
    justifyContent: 'center',
    overflow: 'hidden',
    transition: 'background 100ms ease-out 0s',
    borderRadius:' 4.2rem',
    height: '3.2rem',
    padding: '0px 2rem',
  },
  cardContent:{
    padding:'10px 0px 10px 0px',
   
  },
  detailscard:{
    padding:'10px',
    borderRadius: '20px',
    marginTop: '5px',
    '&:hover': {
      // your hover styles here, e.g.
      backgroundColor: '#dfdfdf', // This will give a light grey background on hover
  },
  }
}));