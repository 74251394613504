import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Post from '../Posts/Post/Post';
import { Grid, Typography, Divider } from '@material-ui/core';
import CustomSpinner from '../CustomSpinner';
import { getPostsByTag } from '../../actions/posts';

const PostsByTags = () => {
    const { tagName } = useParams();
    const dispatch = useDispatch();
    const { posts, isLoading } = useSelector((state) => state.posts);

    useEffect(() => {
        console.log(`Fetching posts for tag: ${tagName}`); // Added log
        dispatch(getPostsByTag(tagName));
    }, [tagName, dispatch]);
    console.log(posts); 

    const loadingStyles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    };

    // Show loading spinner until posts are fully loaded
    if (isLoading) {
        return (
            <div style={loadingStyles}>
                <CustomSpinner size="7em" style={{ color: '#1a866b' }} />
            </div>
        );
    }

    // Once posts are loaded, but none match the filter
    if (!posts.length) {
        return <Typography variant="h6" align="center">No posts found for this tag.</Typography>;
    }

    // Render the posts
    return (
        <>
            <Typography variant="h5">Alle Artikel aus der Rubrik <span style={{ color: '#1a866b' }}>{tagName.replace(/-/g, ' & ')}</span></Typography>
            <Divider style={{ margin: '20px 0' }} />
            <Grid container alignItems="stretch" spacing={3}>
                {posts.map((post, index) => (
                    <Grid key={post._id} item xs={12} sm={6} md={4}>
                        <Post post={post} index={index} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default PostsByTags;
