import React, { useState } from 'react';
import axios from 'axios'; // make sure you are using this if necessary
import useStyles from './styles'; // Make sure the path to your styles.js is correct
import { TextField, Button, Typography, Container, Paper, Grid } from '@material-ui/core';
import { subscribeToNewsletter } from '../../api'; // Adjust the import path as necessary
import newsletter from '../../images/news.jpg';
import confetti from 'canvas-confetti';

const NewsletterForm = () => {
    const [subscriber, setSubscriber] = useState({
        firstName: '',
        lastName: '',
        email: '',
    });
    const classes = useStyles();

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const triggerConfetti = () => {
        confetti({
            angle: 60,
            spread: 55,
            particleCount: 100,
            origin: { x: 0 }
        });
        confetti({
            angle: 120,
            spread: 55,
            particleCount: 100,
            origin: { x: 1 }
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        try {
            const response = await subscribeToNewsletter(subscriber);
            if (response.data.message) {
                setSuccessMessage(response.data.message);
                setSubscriber({ firstName: '', lastName: '', email: '' }); // Reset form
                triggerConfetti(); // Trigger confetti effectform

            }
        } catch (error) {
            console.error(error.response.data.message);
            setErrorMessage('Subscription failed. Please try again later.');
        }
    };

    const handleChange = (e) => {
        setSubscriber({ ...subscriber, [e.target.name]: e.target.value });
    };

    return (
        <Container component="main" maxWidth="md">
            <Grid container spacing={3} className={classes.flexContainer}>
                <Grid item xs={12} md={6} className={classes.imageSide}>
                    {/* Place your image here */}
                    <img src={newsletter} alt="Newsletter" className={classes.newsletterImage} />
                </Grid>
                <Grid item xs={12} md={6} className={classes.formSide}>
                    <Paper className={classes.paper} >
                        <Typography className={classes.subtitle}> Lass dich inspirieren</Typography>
                        <Typography className={classes.subtext}> Abonnieren Sie unseren Newsletter und gehören Sie zu den Ersten, die von Neuheiten, Aktionen und Sonderangeboten erfahren.
</Typography>
                        {successMessage ? (
                            <div className={classes.successmessage}>{successMessage}</div>
                        ) : (
                             <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        className={classes.inputField}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="firstName"
                        label="Vorname"
                        name="firstName"
                        autoComplete="fname"
                        value={subscriber.firstName}
                        onChange={handleChange}
                    />
                    <TextField
                        className={classes.inputField}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="lastName"
                        label="Nachname"
                        name="lastName"
                        autoComplete="lname"
                        value={subscriber.lastName}
                        onChange={handleChange}
                    />
                    <TextField
                        className={classes.inputField}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Addresse"
                        name="email"
                        autoComplete="email"
                        value={subscriber.email}
                        onChange={handleChange}
                    />
                    <Button
                        className={classes.submit}
                        type="submit"
                        fullWidth
                        variant="contained"
                    >
                        Anmelden
                    </Button>
                </form>
                        )}
                        {errorMessage && <div className="error-message">{errorMessage}</div>}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NewsletterForm;
