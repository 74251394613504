
import contactimg from '../../images/contact.jpg';

// style.js
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px', // Set a max width for the form
    margin: '0 auto', // Center the form
  },
  inputField: {
    // Override the default input field styles as necessary
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#19866c', // Custom color for the label on focus
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)', // Default border color
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)', // Border color on hover (optional: you can change this)
      },
      '&.Mui-focused fieldset': {
        borderColor: '#19866c', // Custom color for the border on focus
      },
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: 'black',
    color:'white',
  

    border: 'none',
    '&:hover': {
      backgroundColor: '#19866c !important', // Button hover color
    },
  },
  
  textareaField: {
    margin: theme.spacing(1, 0), // Spacing between fields
    padding: theme.spacing(2), // Padding inside the fields
    borderRadius: '4px', // Rounded corners for the fields
    border: '1px solid #ccc', // Border color
    '&:focus': {
      outline: 'none',
      borderColor: '#3f51b5', // Border color when the field is focused
    },
    resize: 'vertical', // Allows vertical resizing, you can set to 'none' to disable resizing
    fontFamily: 'Roboto, sans-serif', // Keep font consistent with the rest of the inputs
    fontSize: '1rem', // Match font-size with the input fields
    lineHeight: '1.5', // Match line-height with the input fields for better readability
  },
  // Add other styles here if necessary
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius:'20px'
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
 
  // styles.js



  background: {
    background: `linear-gradient(to right, rgb(0 0 0 / 92%), transparent), url(${contactimg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
    },
  },
  contacttitle:{
    color: 'white',
    fontSize: '25px',
    boxShadow: 'none',
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 400,
    paddingTop: '10px',
    marginBottom: '5px',
    letterSpacing: '0.03em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '17px', // Height for mobile screens
    },
  }
  ,
  contacttext: {
    color: 'white',
    fontSize: '14px',
    boxShadow: 'none',
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 400,
    paddingTop: '10px',
    marginBottom: '5px',
    letterSpacing: '0.03em',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px', // Height for mobile screens
    },
  },
  contactTextContainer: {
    // Adjust the width as needed
    padding: '20px', // This adds padding inside the text container
    margin: '0 auto', // This centers the text container in the flex parent
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // This centers the text vertically
    [theme.breakpoints.down('sm')]: {
      flexDirection:'column', // Height for mobile screens
    },
  },
  contactcontent:{
    color: 'white', 
    width:'450px',
    [theme.breakpoints.down('sm')]: {
      width:'300px', // Height for mobile screens
    },
  }
 
}));

